import { useDispatch, useSelector } from "react-redux";
import {
  createAdminEndpoint,
  updateAdminEndpoint,
  updateLocalAdminEndpoint,
} from "../../../store/actions/adminEndpointsActions";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import BranchSelection from "../../filterForm/BranchSelection";
import _ from "lodash";
import { Box, Button } from "@chakra-ui/react";
import AudienceSelection from "../adminEndpointForm/AudienceSelection";
import InputComponent from "../../averly/Input";
import { resetDrawer } from "../../../store/actions/miscAction";
import OrganizationsSelection from "../adminEndpointForm/OrganizationsSelection";

const AdminEndpointForm = () => {
  const dispatch = useDispatch();
  const { adminEndpoint, creatingAdminEndpoint } = useSelector(
    (state) => state.adminEndpointsReducer
  );
  const adminEndpointClone = _.cloneDeep(adminEndpoint);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(
        updateLocalAdminEndpoint({ ...adminEndpointClone, organizationId })
      );
    },

    onBranchChange: (branchId) => {
      dispatch(updateLocalAdminEndpoint({ ...adminEndpointClone, branchId }));
    },

    onAudienceChange: (audienceId) => {
      dispatch(updateLocalAdminEndpoint({ ...adminEndpointClone, audienceId }));
    },

    onNameChange: (name) => {
      dispatch(updateLocalAdminEndpoint({ ...adminEndpointClone, name }));
    },

    onDescriptionChange: (description) => {
      dispatch(
        updateLocalAdminEndpoint({ ...adminEndpointClone, description })
      );
    },

    onOrganizationsChange: (organizations) => {
      dispatch(
        updateLocalAdminEndpoint({ ...adminEndpointClone, organizations })
      );
    },

    onEndpointCreated: () => {
      dispatch(updateLocalAdminEndpoint({}));
      dispatch(resetDrawer());
    },
  };

  return (
    <Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={adminEndpointClone?.organizationId || ""}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Enter Endpoint Name"
          placeholder="Name"
          value={adminEndpointClone?.name}
          onChange={formActions.onNameChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Enter Endpoint Description"
          placeholder="Description"
          value={adminEndpointClone?.description}
          onChange={formActions.onDescriptionChange}
        />
      </Box>

      <Box mb={5}>
        <BranchSelection
          selectedOrganizationId={adminEndpointClone?.organizationId}
          onChange={formActions.onBranchChange}
          value={adminEndpointClone?.branchId}
        />
      </Box>
      <Box mb={5}>
        <OrganizationsSelection
          onChange={formActions.onOrganizationsChange}
          organizations={adminEndpointClone?.organizations}
        />
      </Box>

      <Box mb={5}>
        <AudienceSelection
          onChange={formActions.onAudienceChange}
          value={adminEndpointClone?.audienceId || ""}
        />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        {_.isEmpty(adminEndpointClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingAdminEndpoint}
            loadingText="Creating Endpoint..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                createAdminEndpoint(
                  adminEndpointClone,
                  formActions.onEndpointCreated
                )
              );
            }}
          >
            Create Endpoint
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingAdminEndpoint}
            loadingText="Updating Endpoint..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                updateAdminEndpoint(
                  adminEndpointClone,
                  formActions.onEndpointCreated
                )
              );
            }}
          >
            Update Endpoint
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AdminEndpointForm;
