import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import MethodSelection from "../endpointForm/MethodSelection";
import InputComponent from "../../averly/Input";
import { resetDrawer, updateDrawer } from "../../../store/actions/miscAction";
import {
  createEndpoint,
  updateEndpoint,
  updateLocalEndpoint,
} from "../../../store/actions/endpointsActions";
import ContentTypeSelection from "../endpointForm/ContentTypeselection";
import ResponseCodesSelection from "../endpointForm/ResponseCodesSelection";
import BodyTypeSelection from "../endpointForm/BodyTypeSelection";
import { BODY_OPTIONS } from "../../../store/constants";
import RawDataTypeSelection from "../endpointForm/RawDataTypeSelection";
import AuthorizationSelection from "../endpointForm/AuthorizationSelection";
import BranchSelection from "../../filterForm/BranchSelection";

const EndpointForm = () => {
  const dispatch = useDispatch();
  const { endpoint, creatingEndpoint } = useSelector(
    (store) => store.endpointsReducer
  );
  const endpointClone = _.cloneDeep(endpoint);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, organizationId }));
    },

    onBranchChange: (branchId) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, branchId }));
    },
    onEndpointNameChange: (name) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, name }));
    },

    onEndpointChange: (endpoint) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, endpoint }));
    },
    onEndpointPortChange: (port) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, port }));
    },
    onEndpointServerChange: (server) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, server }));
    },
    onEndpointMethodChange: (method) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, method }));
    },
    onEndpointResponseCodesChange: (responseCodes) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, responseCodes }));
    },
    onEndpointContentTypeChange: (contentType) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, contentType }));
    },

    onBodyTypeChange: (body) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, body }));
    },
    onRawDataTypeChange: (rawDataType) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, rawDataType }));
    },
    onEndpointProtocolChange: (protocol) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, protocol }));
    },

    onAuthorizationChange: (authorization) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, authorization }));
    },

    onExchangeChange: (exchange) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, exchange }));
    },

    onRoutingKeyChange: (routingKey) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, routingKey }));
    },

    onEndpointTypeChange: (endpointType) => {
      delete endpointClone.tran;
      delete endpointClone.store;
      delete endpointClone.chain;
      delete endpointClone.pos;
      delete endpointClone.branchId;
      dispatch(updateLocalEndpoint({ ...endpointClone, endpointType }));
    },

    onPosChange: (pos) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, pos }));
    },

    onTranChange: (tran) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, tran }));
    },
    onChainChange: (chain) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, chain }));
    },

    onStoreChange: (store) => {
      dispatch(updateLocalEndpoint({ ...endpointClone, store }));
    },
    onEndpointCreated: () => {
      dispatch(updateLocalEndpoint({}));
      dispatch(resetDrawer());
    },

    onClone: () => {
      delete endpointClone.id;
      dispatch(updateLocalEndpoint(endpointClone));
      const drawer = {
        title: "Create Endpoint",
        isOpen: 1,
        content: { id: null, component: "EndpointForm" },
      };
      dispatch(updateDrawer(drawer));
    },
  };

  return (
    <Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={endpointClone?.organizationId || ""}
        />
      </Box>
      <Box mb={5}>
        <InputComponent
          label="Name"
          placeholder="Payments Endpoint"
          value={endpointClone?.name}
          onChange={formActions.onEndpointNameChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Endpoint"
          placeholder="/payments"
          value={endpointClone?.endpoint}
          onChange={formActions.onEndpointChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Server"
          placeholder="averly.com.na"
          value={endpointClone?.server}
          onChange={formActions.onEndpointServerChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Protocol"
          placeholder="https"
          value={endpointClone?.protocol}
          onChange={formActions.onEndpointProtocolChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Port"
          placeholder="8080"
          value={endpointClone?.port}
          onChange={formActions.onEndpointPortChange}
        />
      </Box>
      <Box mb={5}>
        <ResponseCodesSelection
          onChange={formActions.onEndpointResponseCodesChange}
          responseCodes={endpointClone?.responseCodes}
        />
      </Box>
      <Box mb={5}>
        <MethodSelection
          value={endpointClone?.method}
          onChange={formActions.onEndpointMethodChange}
        />
      </Box>
      <Box mb={5}>
        <ContentTypeSelection
          value={endpointClone?.contentType}
          onChange={formActions.onEndpointContentTypeChange}
        />
      </Box>

      <Box mb={5}>
        <BodyTypeSelection
          value={endpointClone?.body}
          onChange={formActions.onBodyTypeChange}
        />
      </Box>

      {endpointClone?.body === BODY_OPTIONS.RAW ? (
        <Box mb={5}>
          <RawDataTypeSelection
            value={endpointClone?.rawDataType}
            onChange={formActions.onRawDataTypeChange}
          />
        </Box>
      ) : null}

      <Box mb={5}>
        <AuthorizationSelection
          value={endpointClone?.authorization}
          onChange={formActions.onAuthorizationChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Exchange (For PubSub)"
          placeholder="default"
          value={endpointClone?.exchange}
          onChange={formActions.onExchangeChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Routing Key (For PubSub)"
          placeholder="default"
          value={endpointClone?.routingKey}
          onChange={formActions.onRoutingKeyChange}
        />
      </Box>

      <Box mb={5}>
        <FormControl>
          <FormLabel>Select endpoint Type:</FormLabel>
          <RadioGroup
            onChange={formActions.onEndpointTypeChange}
            value={endpointClone?.endpointType}
          >
            <Stack direction="row">
              <Radio value="0">Other</Radio>
              <Radio value="1">Payment Gateway</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        {Number(endpointClone?.endpointType) === 1 ? (
          <Box>
            <Box mb={5}>
              <BranchSelection
                selectedOrganizationId={endpointClone?.organizationId}
                onChange={formActions.onBranchChange}
                value={endpointClone?.branchId}
              />
            </Box>
            <Box mb={5}>
              <InputComponent
                label="Pos"
                placeholder="80001001"
                value={endpointClone?.pos}
                onChange={formActions.onPosChange}
              />
            </Box>

            <Box mb={5}>
              <InputComponent
                label="Tran"
                placeholder="10"
                value={endpointClone?.tran}
                onChange={formActions.onTranChange}
              />
            </Box>

            <Box mb={5}>
              <InputComponent
                label="Chain"
                placeholder="Test"
                value={endpointClone?.chain}
                onChange={formActions.onChainChange}
              />
            </Box>

            <Box mb={5}>
              <InputComponent
                label="Store"
                placeholder="Averly000001"
                value={endpointClone?.store}
                onChange={formActions.onStoreChange}
              />
            </Box>
          </Box>
        ) : null}
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        {!_.isEmpty(endpointClone?.id) ? (
          <Button h="56px" mr={5} onClick={formActions.onClone}>
            Clone Endpoint{" "}
          </Button>
        ) : null}
        {_.isEmpty(endpointClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingEndpoint}
            loadingText="Creating Endpoint..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                createEndpoint(endpointClone, formActions.onEndpointCreated)
              );
            }}
          >
            Create Endpoint
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingEndpoint}
            loadingText="Updating Endpoint..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                updateEndpoint(endpointClone, formActions.onEndpointCreated)
              );
            }}
          >
            Update Endpoint
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EndpointForm;
