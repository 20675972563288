import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer } from "../../../store/actions/miscAction";
import { useState } from "react";
import { deleteEndpoint, updateLocalEndpoint } from "../../../store/actions/endpointsActions";

const DeleteEndpointForm = () => {
  const dispatch = useDispatch();
  const { endpoint: endpointObject } = useSelector((state) => state.endpointsReducer);
  const endpointClone = _.cloneDeep(endpointObject);
  const { name, endpoint, server } = endpointClone;
  const [localName, setlocalName] = useState("");

  const onEndpointDeleted = ()=>{
    dispatch(updateLocalEndpoint({}));
    dispatch(resetDrawer());
  }
  return (
    <Box>
      <Box bgColor={"#F8F3D9"} borderRadius={6}>
        <Text p={5} color={"gray.500"}>
          Deleting an endpoint is permanent. Please make sure you are deleting the
          correct endpoint.
        </Text>
      </Box>

      <Box>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Name:
          </Text>
          <Text>{name}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Server:
          </Text>
          <Text>{server}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Endpoint:
          </Text>
          <Text>{endpoint}</Text>
        </HStack>

        <Divider mt={5} />

        <Box>
          <HStack>
            <Text
              alignSelf="left"
              color="gray.600"
              fontWeight="bold"
              whiteSpace="nowrap"
              fontSize={14}
            >
              Type the endpoint name of the module you want to delete
            </Text>
            <Text as="span" color="red" mt={2}>
              *
            </Text>
          </HStack>
          <Input
            placeholder="type"
            value={localName}
            onChange={(e) => setlocalName(e.target.value)}
          />
        </Box>

        <Divider mt={5} />

        <ButtonGroup mt={10}>
          <Button onClick={() => dispatch(resetDrawer())}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={()=>dispatch(deleteEndpoint(endpointClone.id, onEndpointDeleted))}
            loadingText={"Deleting Endpoint..."}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeleteEndpointForm;
