import { Box, Heading} from "@chakra-ui/react";


const Dashboard = () => {

  return (
    <Box>
      <Heading size="md" fontWeight={500} color="var(--black-dry)">Welcome to the SS Cloud Console</Heading>
      
    </Box>
  );
};

export default Dashboard;
