import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer } from "../../../store/actions/miscAction";
import { useState } from "react";
import { deleteModule, updateLocalModule } from "../../../store/actions/modulesActions";

const DeleteModuleForm = () => {
  const dispatch = useDispatch();
  const { module } = useSelector((state) => state.modulesReducer);
  const moduleClone = _.cloneDeep(module);
  const { name, system, moduleType } = moduleClone;
  const [localName, setlocalName] = useState("");

  const onModuleDeleted = ()=>{
    dispatch(updateLocalModule({}));
    dispatch(resetDrawer());
  }
  return (
    <Box>
      <Box bgColor={"#F8F3D9"} borderRadius={6}>
        <Text p={5} color={"gray.500"}>
          Deleting a module is permanent. Please make sure you are deleting the
          correct module.
        </Text>
      </Box>

      <Box>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Name:
          </Text>
          <Text>{name}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            System:
          </Text>
          <Text>{Number(system) === 1 ? "All Organizations" : "Single Organization"}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Module Type:
          </Text>
          <Text>{Number(moduleType) === 1 ? "Sign In" : "Other"}</Text>
        </HStack>

        <Divider mt={5} />

        <Box>
          <HStack>
            <Text
              alignSelf="left"
              color="gray.600"
              fontWeight="bold"
              whiteSpace="nowrap"
              fontSize={14}
            >
              Type the module name of the module you want to delete
            </Text>
            <Text as="span" color="red" mt={2}>
              *
            </Text>
          </HStack>
          <Input
            placeholder="type"
            value={localName}
            onChange={(e) => setlocalName(e.target.value)}
          />
        </Box>

        <Divider mt={5} />

        <ButtonGroup mt={10}>
          <Button onClick={() => dispatch(resetDrawer())}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={()=>dispatch(deleteModule(moduleClone.id, onModuleDeleted))}
            loadingText={"Deleting module..."}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeleteModuleForm;
