import { Box } from "@chakra-ui/react";
import FilterForm from "../components/FilterForm";
import { ACTION_BUTTONS } from "../store/constants";
import SectionsComponent from "../components/moduleSections/SectionsComponent";

const ModuleSections = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? <FilterForm actionButton={ACTION_BUTTONS.CREATE_SECTION}/> : null}
      <SectionsComponent />
    </Box>
  );
};

export default ModuleSections;
