import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";
import _ from "lodash";
import { useSelector } from "react-redux";

const AudienceSelection = ({ onChange, value }) => {
  const { audiences } = useSelector((state) => state.defaultReducer);
  const audiencesClone = _.cloneDeep(audiences);
  return (
    <Box>
      <Select
        label="Audience"
        placeholder="Select Audience Type"
        value={value}
        options={audiencesClone}
        onChange={onChange}
      />
    </Box>
  );
};

export default AudienceSelection;
