import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganizationId } from "../../store/actions/defaultAction";

import Select from "../averly/Select";

const OrganizationSelection = ({ onChange = null, value = null }) => {
  const dispatch = useDispatch();
  const { organizationId, organizations } = useSelector(
    (store) => store.defaultReducer
  );

  const handleOnchange = (organizationId) => {
    dispatch(updateOrganizationId(organizationId));
  };

  onChange = onChange || handleOnchange;
  value = value === null ?  organizationId : value;

  return (
    <Box>
      <Select
        label="Organization"
        placeholder="Select an Organization"
        value={value}
        options={organizations}
        onChange={onChange}
      />
    </Box>
  );
};

export default OrganizationSelection;
