import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer } from "../../../store/actions/miscAction";
import { useState } from "react";
import { deleteSubscription, updateLocalSubscription } from "../../../store/actions/subscriptionsActions";

const DeleteSubscriptionForm = () => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.subscriptionsReducer);
  const { modules } = useSelector((state) => state.modulesReducer);
  const subscriptionClone = _.cloneDeep(subscription);
  const { name, moduleId } = subscriptionClone;
  const moduleName = modules.find((a) => a.id === moduleId)?.name;
  const [localName, setlocalName] = useState("");

  const onSubscriptionDeleted = ()=>{
    dispatch(updateLocalSubscription({}));
    dispatch(resetDrawer());
  }
  return (
    <Box>
      <Box bgColor={"#F8F3D9"} borderRadius={6}>
        <Text p={5} color={"gray.500"}>
          Deleting a module subscription is permanent. Please make sure you are deleting the
          correct subscription.
        </Text>
      </Box>

      <Box>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Name:
          </Text>
          <Text>{name}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Module Name:
          </Text>
          <Text>{moduleName}</Text>
        </HStack>

        <Divider mt={5} />

        <Box>
          <HStack>
            <Text
              alignSelf="left"
              color="gray.600"
              fontWeight="bold"
              whiteSpace="nowrap"
              fontSize={14}
            >
              Type the subscription name of the subscription you want to delete
            </Text>
            <Text as="span" color="red" mt={2}>
              *
            </Text>
          </HStack>
          <Input
            placeholder="type"
            value={localName}
            onChange={(e) => setlocalName(e.target.value)}
          />
        </Box>

        <Divider mt={5} />

        <ButtonGroup mt={10}>
          <Button onClick={() => dispatch(resetDrawer())}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={()=>dispatch(deleteSubscription(subscriptionClone.id, onSubscriptionDeleted))}
            loadingText={"Deleting subscription..."}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeleteSubscriptionForm;
