import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import Select from "../../averly/Select";

const AuthEndpointSelection = ({ organizationId, value, onChange }) => {
  const { endpoints } = useSelector((store) => store.endpointsReducer);
  const endpointsClone = _.cloneDeep(endpoints);
  const options = endpointsClone
    .filter((a) => a.organizationId === organizationId)
    .map((a) => {
      return {
        name: `${a.name} - ${a.method} - ${a.endpoint}`,
        id: a.id,
      };
    });

  return (
    <Box>
      <Box>
        <Select
          label="Authorization Endpoint"
          placeholder="Select Endpoint"
          value={value}
          options={options}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default AuthEndpointSelection;
