import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";

// main pages
import Modules from "./pages/Modules";
import Endpoints from "./pages/Endpoints";
import ModulesSubscriptions from "./pages/ModulesSubscriptions";
import StylingAndContent from "./pages/StylingAndContent";
import ModuleSections from "./pages/ModuleSections";
import Authorization from "./pages/Authorization";
import AdminEndpoints from "./pages/AdminEndpoints";

const RoutesComponent = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <Layout>
      <ToastContainer position="bottom-center" />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/endpoints" element={<Endpoints />} />
        <Route
          path="/modules-subscriptions"
          element={<ModulesSubscriptions />}
        />
        <Route path="/module-sections" element={<ModuleSections />} />
        <Route path="/styling-and-content" element={<StylingAndContent />} />
        <Route path="/authorization" element={<Authorization />} />
        <Route path="/admin-endpoints" element={<AdminEndpoints />} />
        {/* organization */}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
};
export default RoutesComponent;
