import { Box } from "@chakra-ui/react";
import { ACTION_BUTTONS } from "../store/constants";
import FilterForm from "../components/FilterForm";
import AdminEndpointsComponent from "../components/adminEndpoints/AdminEndpointsComponents";

const AdminEndpoints = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? (
        <FilterForm actionButton={ACTION_BUTTONS.ADD_ADMIN_ENDPOINT} />
      ) : null}
     <AdminEndpointsComponent/>
    </Box>
  );
};

export default AdminEndpoints;
