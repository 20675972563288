import { ACTION_TYPES } from "../actions/types";

const initialState = {
  endpoints: [],
  endpointsLoading: false,
  creatingEndpoint: false,
  listenEndpointsStart: 0,
  endpoint: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ENDPOINTS:
      return {
        ...state,
        endpoints: action.payload,
      };

    case ACTION_TYPES.LISTEN_ENDPOINTS_START:
      return {
        ...state,
        listenEndpointssStart: action.payload,
      };

    case ACTION_TYPES.LISTEN_ENDPOINTS_CREATE:
      return {
        ...state,
        endpoints: [...state.endpoints, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_ENDPOINTS_UPDATE:
      return {
        ...state,
        endpoints: state.endpoints.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_ENDPOINTS_DELETE:
      return {
        ...state,
        endpoints: state.endpoints.filter((data) => data.id !== action.payload.id),
      };

    case ACTION_TYPES.SET_ENDPOINTS_LOADING:
      return {
        ...state,
        endpointsLoading: action.payload,
      };

    case ACTION_TYPES.UPDATE_LOCAL_ENDPOINT:
      return {
        ...state,
        endpoint: action.payload,
      };

    case ACTION_TYPES.UPDATE_CREATING_ENDPOINT:
      return {
        ...state,
        creatingEndpoint: action.payload,
      };
    default:
      return state;
  }
}
