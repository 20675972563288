import { Box } from "@chakra-ui/react"
import FilterForm from "../components/FilterForm";
import { ACTION_BUTTONS } from "../store/constants";
import AuthorizationComponent from "../components/authorization/AuthorizationComponent";

const Authorization = ({ showFilterForm = 1 })=>{
    return (<Box>
              {showFilterForm ? <FilterForm actionButton={ACTION_BUTTONS.ADD_AUTHORIZATION} /> : null}
        <AuthorizationComponent/>
    </Box>)
}

export default Authorization;