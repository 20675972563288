import { Box, Text } from "@chakra-ui/react";
import MultiSelect from "react-select";

const ResponseCodesSelection = ({onChange, responseCodes}) => {

  const options = [
    {label: "200", id: 200},
    {label: "201", id: 201},
    {label: "202", id: 202},
    {label: "203", id: 203},
    {label: "300", id: 300},
    {label: "301", id: 301},
    {label: "302", id: 302},
    {label: "303", id: 303},
    {label: "400", id: 400},
    {label: "401", id: 401},
    {label: "402", id: 402},
    {label: "403", id: 403},
    {label: "404", id: 404},
    {label: "405", id: 405},
    {label: "500", id: 500},
    {label: "501", id: 501},
    {label: "502", id: 502},
    {label: "503", id: 503},
  ];

  return (
    <Box
      mt="10px"
      w="100%"
      h="auto"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 5px 9px 12px"
    >
      <Text
        h="15px"
        lineHeight="15px"
        fontSize="12px"
        color="#6a6a6a"
      >
        Select Response Codes
      </Text>

        <MultiSelect
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "none", outline: "none", boxShadow: "none", paddingLeft: 0, marginLeft: 0,
            }),
          }}
          options={options.map((a) => ({
            value: a.id,
            label: a.label,
          }))}
          defaultValue={responseCodes?.map((a) => {
            return {
              value: a,
              label: a
            };
          })}
          onChange={(e) => {
            const newValue = e.map((a) => a.value);
            onChange(newValue);
          }}
          isMulti={true}
          isClearable={true}
          isSearchable={true}
          placeholder="Select Response Codes"
        />

    </Box>)
};

export default ResponseCodesSelection;