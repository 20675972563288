import { Box, Button, Text } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    createStyling,
  updateLocalStyling,
  updateStyling,
} from "../../../store/actions/stylingsActions";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import InputComponent from "../../averly/Input";
import { resetDrawer } from "../../../store/actions/miscAction";

const StylingForm = () => {
  const { styling, creatingStyling, stylings } = useSelector(
    (state) => state.stylingsReducer
  );
  const dispatch = useDispatch();
  const stylingClone = _.cloneDeep(styling);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(updateLocalStyling({ ...stylingClone, organizationId }));
    },
    onBackgroundColorChange: (backgroundColor) => {
      dispatch(
        updateLocalStyling({
          ...stylingClone,
          styling: {
            ...stylingClone.styling,
            backgroundColor,
          },
        })
      );
    },

    onNextBtnBackgroundColorChange: (nextBtnBackgroundColor) => {
      dispatch(
        updateLocalStyling({
          ...stylingClone,
          styling: {
            ...stylingClone.styling,
            nextBtnBackgroundColor,
          },
        })
      );
    },

    onSecondaryColorChange: (secondaryColor) => {
      dispatch(
        updateLocalStyling({
          ...stylingClone,
          styling: {
            ...stylingClone.styling,
            secondaryColor,
          },
        })
      );
    },

    onIconColorChange: (iconColor) => {
      dispatch(
        updateLocalStyling({
          ...stylingClone,
          styling: {
            ...stylingClone.styling,
            iconColor,
          },
        })
      );
    },

    onFooterTextChange: (footerText) => {
      dispatch(
        updateLocalStyling({
          ...stylingClone,
          content: {
            ...stylingClone.content,
            footerText,
          },
        })
      );
    },

    onStylingCreated: () => {
      dispatch(updateLocalStyling({}));
      dispatch(resetDrawer());
    },
  };

  return (
    <Box>
      <Box mb={5}>
        <Text fontWeight={"bold"} fontSize={"18px"}>
          Styling
        </Text>
      </Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={stylingClone?.organizationId}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Background Color"
          placeholder="#fff"
          value={stylingClone?.styling?.backgroundColor}
          onChange={formActions.onBackgroundColorChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Next Button Background Color"
          placeholder="#f4f4f4"
          value={stylingClone?.styling?.nextBtnBackgroundColor}
          onChange={formActions.onNextBtnBackgroundColorChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Secondary Color"
          placeholder="#C4C4C4"
          value={stylingClone?.styling?.secondaryColor}
          onChange={formActions.onSecondaryColorChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Icon Color"
          placeholder="#000"
          value={stylingClone?.styling?.iconColor}
          onChange={formActions.onIconColorChange}
        />
      </Box>

      <Box mb={5}>
        <Text fontWeight={"bold"} fontSize={"18px"}>
          Content
        </Text>
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Footer Text"
          placeholder="Averly Digital Solutions"
          value={stylingClone?.content?.footerText}
          onChange={formActions.onFooterTextChange}
        />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        {_.isEmpty(stylingClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingStyling}
            loadingText="Creating Styling..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(createStyling(stylingClone, formActions.onStylingCreated, stylings));
            }}
          >
            Create Styling
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingStyling}
            loadingText="Updating Styling..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                updateStyling(stylingClone, formActions.onStylingCreated)
              );
            }}
          >
            Update Styling
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default StylingForm;
