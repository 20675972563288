import { Box, Icon, Link, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { updateDrawer } from "../../store/actions/miscAction";
import AuthorizationsTable from "./AuthorizationsTable";
import { updateLocalAuthorization } from "../../store/actions/authorizationActions";

const AuthorizationComponent = () => {
  const dispatch = useDispatch();
  const { authorizations } = useSelector((state) => state.authorizationsReducer);
  const { endpoints } = useSelector((state) => state.endpointsReducer);
  const { organizations, organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  let authorizationsClone = _.cloneDeep(authorizations);
  const endpointsClone = _.cloneDeep(endpoints);
  if (organizationId.length > 0) {
    authorizationsClone = authorizationsClone.filter(
      (a) => a.organizationId === organizationId
    );
  }

  const orgsMap = authorizationsClone.reduce((acc, curr) => {
    if (!acc[curr.organizationId]) {
      acc[curr.organizationId] = [];
    }
    const endpoint = endpointsClone.find((a)=> a.id === curr.endpointId);
    acc[curr.organizationId].push({...curr, endpoint: `${endpoint?.name} - ${endpoint?.method} - ${endpoint?.endpoint}`});
    return acc;
  }, {});
  const orgsData = Object.keys(orgsMap).map((organizationId) => {
    const orgAuthorizations = orgsMap[organizationId];

    return {
      orgAuthorizations,
      organizationId,
      name:
        organizations.find((a) => a.id === organizationId)?.name ||
        "Unnamed Org",
    };
  });

  const openDrawer = () => {
    dispatch(updateLocalAuthorization({}));
    const drawer = {
      title: "Create Authorization",
      isOpen: 1,
      content: { component: "AuthorizationForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };
  return (
    <Box>
      {orgsData.length > 0 ? (
        orgsData.map((a, i) => <AuthorizationsTable data={a} key={a.organizationId} />)
      ) : (
        <VStack mt="100px">
          <Box w="128px" h="128px">
            <Icon
              as={IoCloseCircleOutline}
              boxSize="128px"
              color="var(--red-pale)"
            />
          </Box>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            No authorizations created for the current selection.
          </Text>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            <Link color="var(--blue)" onClick={openDrawer}>
              Create an Auth
            </Link>{" "}
            to get started.
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default AuthorizationComponent;
