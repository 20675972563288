import { Box, Text } from "@chakra-ui/react";
import ModuleOnMountEndpointsSelection from "../forms/moduleEndpointsForm/ModuleEndpointsSelection";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateLocalSubscription } from "../../../store/actions/subscriptionsActions";
import { MODULE_ENDPOINTS_TYPES } from "../../../store/constants";

const ModuleEndpointsSelection = ({ sectionId }) => {
  const { subscription } = useSelector((store) => store.subscriptionsReducer);
  const { sections } = useSelector((store) => store.sectionsReducer);
  const sectionsClone = _.cloneDeep(sections);
  const dispatch = useDispatch();
  const subscriptionClone = _.cloneDeep(subscription);

  const formActions = {
    onModuleOnMountEndpointsChange: (newEndpoints) => {
      if (!subscriptionClone.moduleSections) {
        subscriptionClone.moduleSections = [];
      }

      const sectionIndex = subscriptionClone.moduleSections.findIndex(
        (section) => section.sectionId === sectionId
      );

      if (sectionIndex !== -1) {
        subscriptionClone.moduleSections[sectionIndex].moduleOnMountEndpoints =
          newEndpoints;
      } else {
        subscriptionClone.moduleSections.push({
          sectionId,
          moduleOnMountEndpoints: newEndpoints,
          initialLoadEndpoints: [],
          submitEndpoint: "",
          refreshEndpoints: [],
        });
      }

      dispatch(updateLocalSubscription(subscriptionClone));
    },

    onInitialLoadEndpointsChange: (newEndpoints) => {
      if (!subscriptionClone.moduleSections) {
        subscriptionClone.moduleSections = [];
      }

      const sectionIndex = subscriptionClone.moduleSections.findIndex(
        (section) => section.sectionId === sectionId
      );

      if (sectionIndex !== -1) {
        subscriptionClone.moduleSections[sectionIndex].initialLoadEndpoints =
          newEndpoints;
      } else {
        subscriptionClone.moduleSections.push({
          sectionId,
          moduleOnMountEndpoints: [],
          submitEndpoint: "",
          initialLoadEndpoints: newEndpoints,
          refreshEndpoints: [],
        });
      }

      dispatch(updateLocalSubscription(subscriptionClone));
    },

    onSubmitEndpointChange: (newEndpoint) => {
      if (!subscriptionClone.moduleSections) {
        subscriptionClone.moduleSections = [];
      }

      const sectionIndex = subscriptionClone.moduleSections.findIndex(
        (section) => section.sectionId === sectionId
      );

      if (sectionIndex !== -1) {
        subscriptionClone.moduleSections[sectionIndex].submitEndpoint =
          newEndpoint;
      } else {
        subscriptionClone.moduleSections.push({
          sectionId,
          moduleOnMountEndpoints: [],
          submitEndpoint: newEndpoint,
          initialLoadEndpoints: [],
          refreshEndpoints: [],
        });
      }

      dispatch(updateLocalSubscription(subscriptionClone));
    },

    onRefreshEndpointsChange: (newEndpoints) => {
      if (!subscriptionClone.moduleSections) {
        subscriptionClone.moduleSections = [];
      }

      const sectionIndex = subscriptionClone.moduleSections.findIndex(
        (section) => section.sectionId === sectionId
      );

      if (sectionIndex !== -1) {
        subscriptionClone.moduleSections[sectionIndex].refreshEndpoints =
          newEndpoints;
      } else {
        subscriptionClone.moduleSections.push({
          sectionId,
          moduleOnMountEndpoints: [],
          submitEndpoint: "",
          initialLoadEndpoints: [],
          refreshEndpoints: newEndpoints,
        });
      }

      dispatch(updateLocalSubscription(subscriptionClone));
    },
  };

  const moduleName = sectionsClone.find((a) => a.id === sectionId)?.name;
  return (
    <Box>
      <Text fontSize={18}>{moduleName} Section</Text>
      <ModuleOnMountEndpointsSelection
        onChange={formActions.onModuleOnMountEndpointsChange}
        sectionId={sectionId}
        moduleEndPointType={MODULE_ENDPOINTS_TYPES.MODULE_ON_MOUNT}
        label={"Select Module on Mount Endpoints"}
      />

      <ModuleOnMountEndpointsSelection
        onChange={formActions.onInitialLoadEndpointsChange}
        sectionId={sectionId}
        moduleEndPointType={MODULE_ENDPOINTS_TYPES.INITIAL_LOAD}
        label={"Select Initial Load Endpoints"}
      />

      <ModuleOnMountEndpointsSelection
        onChange={formActions.onSubmitEndpointChange}
        sectionId={sectionId}
        moduleEndPointType={MODULE_ENDPOINTS_TYPES.SUBMIT}
        label={"Select Submit Endpoint"}
      />

      <ModuleOnMountEndpointsSelection
        onChange={formActions.onRefreshEndpointsChange}
        sectionId={sectionId}
        moduleEndPointType={MODULE_ENDPOINTS_TYPES.REFRESH_ENDPOINTS}
        label={"Select Refresh Endpoints"}
      />
    </Box>
  );
};

export default ModuleEndpointsSelection;
