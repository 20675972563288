import { Box, Text } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import MultiSelect from "react-select";

const OrganizationsSelection = ({ onChange, organizations }) => {
  const { organizations: options } = useSelector(
    (state) => state.defaultReducer
  );

  const { adminEndpoint } = useSelector((state) => state.adminEndpointsReducer);
  let optionsClone = _.cloneDeep(options);
  optionsClone = optionsClone.filter(
    (a) => a.id !== adminEndpoint.organizationId
  );

  return (
    <Box
      mt="10px"
      w="100%"
      h="auto"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 5px 9px 12px"
    >
      <Text h="15px" lineHeight="15px" fontSize="12px" color="#6a6a6a">
        Select Subscribed Organizations
      </Text>

      <MultiSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            outline: "none",
            boxShadow: "none",
            paddingLeft: 0,
            marginLeft: 0,
          }),
        }}
        options={optionsClone.map((a) => ({
          value: a.id,
          label: a.name,
        }))}
        defaultValue={organizations?.map((a) => {
          return {
            value: a,
            label: a,
          };
        })}
        onChange={(e) => {
          const newValue = e.map((a) => a.value);
          onChange(newValue);
        }}
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        placeholder="Select Subscribed Organizations"
      />
    </Box>
  );
};

export default OrganizationsSelection;
