import { toast } from "react-toastify";
import io from "socket.io-client";
import { DELETEData, GETData, PATCHData, POSTData } from "./events";
import { ACTION_TYPES } from "./types";
import store from "../index";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      dispatch({
        type,
        payload,
      });
    });
  };

export const listenSections = () =>
  listenToEvent("apps_ss_moduleSections", "LISTEN_SECTIONS", 1);

export const fetchSections = () => async (dispatch) => {
  try {
    const endpoint = "/apps/ss/moduleSections";
    dispatch(GETData(endpoint, null, false, ACTION_TYPES.FETCH_SECTIONS));
  } catch (err) {
    console.error("Error fetching module sections:", err);
    throw err;
  }
};

export const createSection = (data, onSectionCreated) => async (dispatch) => {
  if (!data.name || !data.moduleId || !data.organizationId) {
    toast.error("Please fill out all required fields");
    return;
  }
  dispatch(updateCreatingSection(true));
  try {
    const endpoint = "/apps/ss/moduleSections";
    await dispatch(POSTData(endpoint, data, ACTION_TYPES.ADD_SECTION));
  } catch (err) {
    console.error("Error adding module section:", err);
    toast.error("Error adding module section");
    throw err;
  }

  dispatch(updateCreatingSection(false));
  onSectionCreated();
};

export const updateSection = (data, onSectionCreated) => async (dispatch) => {
  if (!data.name || !data.moduleId || !data.organizationId) {
    toast.error("Please fill out all required fields");
    return;
  }
  dispatch(updateCreatingSection(true));
  try {
    const endpoint = "/apps/ss/moduleSections";
    await dispatch(PATCHData(endpoint, data, ACTION_TYPES.ADD_SECTION));
  } catch (err) {
    console.error("Error updating module section:", err);
    toast.error("Error updating module section");
    throw err;
  }

  dispatch(updateCreatingSection(false));
  onSectionCreated();
};

export const deleteSection = (id, onSectionDeleted) => async (dispatch) => {
  try {
    const endpoint = "/apps/ss/moduleSections";
    await dispatch(DELETEData(endpoint, id, ACTION_TYPES.ADD_SECTION));
  } catch (err) {
    console.error("Error deleting module section:", err);
    toast.error("Error deleteting module section");
    throw err;
  }

  onSectionDeleted();
};

export const setSectionsLoading = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SECTIONS_LOADING,
    payload: payload,
  });
};

// UPDATE_LOCAL_MODULE
export const updateLocalSection = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_SECTION,
    payload: payload,
  });
};

// UPDATE_CREATING_MODULE
export const updateCreatingSection = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CREATING_SECTION,
    payload: payload,
  });
};
