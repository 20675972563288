import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer } from "../../../store/actions/miscAction";
import { useState } from "react";
import { deleteAuthorization, updateLocalAuthorization } from "../../../store/actions/authorizationActions";

const DeleteAuthorizationForm = () => {
  const dispatch = useDispatch();
  const { authorization } = useSelector((state) => state.authorizationsReducer);
  const { endpoints } = useSelector((state)=> state.endpointsReducer)
  const endpointsClone = _.cloneDeep(endpoints);
  const authorizationClone = _.cloneDeep(authorization);
  const { name, endpointId } = authorizationClone;
  const [localName, setlocalName] = useState("");
  const endpointObj = endpointsClone.find((a)=>a.id === endpointId);
  const endpoint = `${endpointObj?.name} - ${endpointObj?.method} - ${endpointObj?.endpoint}`;

  const onAuthDeleted = ()=>{
    dispatch(updateLocalAuthorization({}));
    dispatch(resetDrawer());
  }
  return (
    <Box>
      <Box bgColor={"#F8F3D9"} borderRadius={6}>
        <Text p={5} color={"gray.500"}>
          Deleting an authorization is permanent. Please make sure you are deleting the
          correct authorization.
        </Text>
      </Box>

      <Box>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Name:
          </Text>
          <Text>{name}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Endpoint:
          </Text>
          <Text>{endpoint}</Text>
        </HStack>

        <Divider mt={5} />

        <Box>
          <HStack>
            <Text
              alignSelf="left"
              color="gray.600"
              fontWeight="bold"
              whiteSpace="nowrap"
              fontSize={14}
            >
              Type the authorization name of the authorization you want to delete
            </Text>
            <Text as="span" color="red" mt={2}>
              *
            </Text>
          </HStack>
          <Input
            placeholder="type"
            value={localName}
            onChange={(e) => setlocalName(e.target.value)}
          />
        </Box>

        <Divider mt={5} />

        <ButtonGroup mt={10}>
          <Button onClick={() => dispatch(resetDrawer())}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={()=>dispatch(deleteAuthorization(authorizationClone.id, onAuthDeleted))}
            loadingText={"Deleting authorization..."}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeleteAuthorizationForm;
