import { Box, Icon, Link, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { updateDrawer } from "../../store/actions/miscAction";
import { updateLocalModule } from "../../store/actions/modulesActions";
import ModulesTable from "./ModulesTable";

const ModulesComponent = () => {
  const dispatch = useDispatch();
  const { modules } = useSelector((state) => state.modulesReducer);
  const { organizations, organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  let modulesClone = _.cloneDeep(modules);
  const systemModules = modulesClone.filter((a) => Number(a.system) === 1); //sysModules have no organizationId
  if (organizationId.length > 0) {
    modulesClone = modulesClone.filter(
      (a) => a.organizationId === organizationId
    );
  }

  const orgsMap = modulesClone.reduce((acc, curr) => {
    if (!acc[curr.organizationId]) {
      acc[curr.organizationId] = [];
    }
    acc[curr.organizationId].push(curr);
    return acc;
  }, {});
  const orgsData = Object.keys(orgsMap).map((organizationId) => {
    const orgModules = orgsMap[organizationId];

    return {
      orgModules,
      organizationId,
      name:
        organizations.find((a) => a.id === organizationId)?.name ||
        "System Modules",
    };
  });

  const openDrawer = () => {
    dispatch(updateLocalModule({}));
    const drawer = {
      title: "Create Module",
      isOpen: 1,
      content: { component: "ModuleForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };
  return (
    <Box>
      {orgsData.length > 0 ? (
        orgsData.map((a, i) => <ModulesTable data={a} key={a.organizationId} />)
      ) : (
        <VStack mt="100px">
          <Box w="128px" h="128px">
            <Icon
              as={IoCloseCircleOutline}
              boxSize="128px"
              color="var(--red-pale)"
            />
          </Box>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            No modules created for the current selection.
          </Text>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            <Link color="var(--blue)" onClick={openDrawer}>
              Create a module
            </Link>{" "}
            to get started.
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default ModulesComponent;
