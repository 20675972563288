import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import Select from "../../averly/Select";
import { MODULE_TYPES } from "../../../store/constants";

const ModuleSelection = ({ organizationId, value, onChange }) => {
  const { modules } = useSelector((store) => store.modulesReducer);
  const { subscriptions } = useSelector((store) => store.subscriptionsReducer);
  const modulesClone = _.cloneDeep(modules);
  const subscriptionsClone = _.cloneDeep(subscriptions);
  const orgSignInModules = modulesClone
    .filter(
      (a) =>
        a.organizationId === organizationId &&
        Number(a.moduleType) === MODULE_TYPES.SIGN_IN
    )
    .map((a) => a.id);
  const orgSubscriptionModules = subscriptionsClone
    .filter((a) => a.organizationId === organizationId)
    .map((a) => a.moduleId);

  const orgSignInModulesInSubscriptions = orgSignInModules.filter((a) =>
    orgSubscriptionModules.includes(a)
  );


  // Only one sign In subscription allowed
  const orgModules = modulesClone.filter(
    (a) =>
      a.organizationId === organizationId ||
      (Number(a.system) === 1 &&
        !orgSignInModulesInSubscriptions.includes(a.id))
  );

  return (
    <Box>
      <Box>
        <Select
          label="Subscription Module"
          placeholder="Select Module"
          value={value}
          options={orgModules}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default ModuleSelection;
