import { connect } from "react-redux";
import { Box, Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {
  updateReportRange,
  updateOrganizationId,
} from "../store/actions/defaultAction";

import Nav from "./sidebar/Nav";

import Logo from "../assets/img/logo-dark.svg";

function Sidebar() {

  return (
    <Box
      className="sidebar"
      w="260px"
      backgroundColor="var(--white)"
      borderRight="1px solid #ebebeb"
      position="fixed"
      height="100vh"
      overflowY="auto"
      paddingBottom={0}
    >
      <Box p="15px 15px 0">
        <Image src={Logo} alt="logo" />
      </Box>
      <Box p="15px 15px 0" >
        <Box mt="15px">
          <Nav />
        </Box>

        {/* <BiLinks /> */}
      </Box>
    </Box>
  );
}

export default connect(null, {
  updateReportRange,
  updateOrganizationId,
})(Sidebar);
