import { Box, Button, Textarea } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import {
  createModule,
  updateLocalModule,
  updateModule,
} from "../../../store/actions/modulesActions";
import InputComponent from "../../averly/Input";
import ModuleTypeSelection from "../moduleForm/ModuleTypeSelection";
import ModuleScopeSelection from "../moduleForm/ModuleScopeSelection";
import TextareaComponent from "../../averly/Textarea";
import { resetDrawer } from "../../../store/actions/miscAction";

const ModuleForm = ({ id }) => {
  const dispatch = useDispatch();
  const { module, creatingModule } = useSelector(
    (store) => store.modulesReducer
  );
  const moduleClone = _.cloneDeep(module);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(updateLocalModule({ ...moduleClone, organizationId }));
    },
    onModuleNameChange: (name) => {
      dispatch(updateLocalModule({ ...moduleClone, name }));
    },
    onModuleTypeChange: (moduleType) => {
      dispatch(updateLocalModule({ ...moduleClone, moduleType }));
    },
    onModuleScopeChange: (system) => {
      if (Number(system) === 1) {
        moduleClone.organizationId = "";
      }
      dispatch(updateLocalModule({ ...moduleClone, system }));
    },
    onModuleDescriptionChange: (description) => {
      dispatch(updateLocalModule({ ...moduleClone, description }));
    },
    onModuleCreated: () => {
      dispatch(updateLocalModule({}));
      dispatch(resetDrawer());
    },
  };
  return (
    <Box>
      <Box mb={5}>
        <InputComponent
          label="Name"
          placeholder="Sign In"
          value={moduleClone?.name}
          onChange={formActions.onModuleNameChange}
        />
      </Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={moduleClone?.organizationId}
        />
      </Box>

      <Box mb={5}>
        <ModuleTypeSelection
          onChange={formActions.onModuleTypeChange}
          value={moduleClone?.moduleType}
        />
      </Box>

      <Box mb={5}>
        <ModuleScopeSelection
          onChange={formActions.onModuleScopeChange}
          value={moduleClone?.system}
        />
      </Box>

      <Box mb={5}>
        <TextareaComponent
          onChange={formActions.onModuleDescriptionChange}
          value={moduleClone?.description}
          placeholder={"Type Module Description"}
          label={"Module Description"}
        />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        {_.isEmpty(moduleClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingModule}
            loadingText="Creating Module..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(createModule(moduleClone, formActions.onModuleCreated));
            }}
          >
            Create Module
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingModule}
            loadingText="Updating Module..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(updateModule(moduleClone, formActions.onModuleCreated));
            }}
          >
            Update Module
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ModuleForm;
