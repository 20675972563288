import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer } from "../../../store/actions/miscAction";
import { useState } from "react";
import {
  deleteAdminEndpoint,
  updateLocalAdminEndpoint,
} from "../../../store/actions/adminEndpointsActions";

const DeleteAdminEndpointForm = () => {
  const dispatch = useDispatch();
  const { adminEndpoint } = useSelector((state) => state.adminEndpointsReducer);
  const adminEndpointClone = _.cloneDeep(adminEndpoint);
  const { name, description } = adminEndpointClone;
  const [localName, setlocalName] = useState("");

  const ondeleted = () => {
    dispatch(updateLocalAdminEndpoint({}));
    dispatch(resetDrawer());
  };
  return (
    <Box>
      <Box bgColor={"#F8F3D9"} borderRadius={6}>
        <Text p={5} color={"gray.500"}>
          Deleting an endpoint is permanent. Please make sure you are deleting
          the correct endpoint.
        </Text>
      </Box>

      <Box>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Name:
          </Text>
          <Text>{name}</Text>
        </HStack>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            Description:
          </Text>
          <Text>{description}</Text>
        </HStack>

        <Divider mt={5} />

        <Box>
          <HStack>
            <Text
              alignSelf="left"
              color="gray.600"
              fontWeight="bold"
              whiteSpace="nowrap"
              fontSize={14}
            >
              Type the endpoint name of the endpoint you want to delete
            </Text>
            <Text as="span" color="red" mt={2}>
              *
            </Text>
          </HStack>
          <Input
            placeholder="type"
            value={localName}
            onChange={(e) => setlocalName(e.target.value)}
          />
        </Box>

        <Divider mt={5} />

        <ButtonGroup mt={10}>
          <Button onClick={() => dispatch(resetDrawer())}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={() =>
              dispatch(deleteAdminEndpoint(adminEndpointClone.id, ondeleted))
            }
            loadingText={"Deleting endpoint..."}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeleteAdminEndpointForm;
