import { Box } from "@chakra-ui/react";
import FilterForm from "../components/FilterForm";
import { ACTION_BUTTONS } from "../store/constants";
import ModulesComponent from "../components/modules/ModulesComponent";

const Modules = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? (
        <FilterForm actionButton={ACTION_BUTTONS.CREATE_MODULE} />
      ) : null}
      <ModulesComponent />
    </Box>
  );
};

export default Modules;
