import { Box, Text } from "@chakra-ui/react";
import MultiSelect from "react-select";
import _ from "lodash";
import { useSelector } from "react-redux";

const AppOnMountEndpointsSelection = ({
  organizationId,
  onChange,
  appOnMountEndpoints,
}) => {
  const { endpoints } = useSelector((store) => store.endpointsReducer);
  const endpointsClone = _.cloneDeep(endpoints);

  const endpointsData = endpointsClone
    .filter((a) => a.organizationId === organizationId)
    .map((a) => {
      return {
        label: `${a.name} - ${a.method} - ${a.endpoint}`,
        value: a.id,
      };
    });

  return (
    <Box
      mt="10px"
      w="100%"
      h="auto"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 5px 9px 12px"
    >
      <Text h="15px" lineHeight="15px" fontSize="12px" color="#6a6a6a">
        Select App On Mount Endpoints
      </Text>

      <MultiSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            outline: "none",
            boxShadow: "none",
            paddingLeft: 0,
            marginLeft: 0,
          }),
        }}
        options={endpointsData.map((a) => ({
          value: a.value,
          label: a.label,
        }))}
        defaultValue={appOnMountEndpoints?.map((a) => {
          const endpoint = endpointsClone.find((b) => b.id === a);

          return {
            value: a,
            label: `${endpoint.name} - ${endpoint.method} - ${endpoint.endpoint}`,
          };
        })}
        onChange={(e) => {
          const newValue = e.map((a) => a.value);
          onChange(newValue);
        }}
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        placeholder="Select Endpoints"
      />
    </Box>
  );
};

export default AppOnMountEndpointsSelection;
