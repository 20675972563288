import { Box, Button } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import InputComponent from "../../averly/Input";
import { resetDrawer } from "../../../store/actions/miscAction";
import {
  createAuthorization,
  updateAuthorization,
  updateLocalAuthorization,
} from "../../../store/actions/authorizationActions";
import AuthEndpointSelection from "../authorizationForm/AuthEndpointSelection";

const AuthorizationForm = ({ id }) => {
  const dispatch = useDispatch();
  const { authorization, creatingAuthorization } = useSelector(
    (store) => store.authorizationsReducer
  );
  const authorizationClone = _.cloneDeep(authorization);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(
        updateLocalAuthorization({ ...authorizationClone, organizationId })
      );
    },
    onAuthNameChange: (name) => {
      dispatch(updateLocalAuthorization({ ...authorizationClone, name }));
    },

    onEndpointChange: (endpointId) => {
      dispatch(updateLocalAuthorization({ ...authorizationClone, endpointId }));
    },
    onAuthorizationCreated: () => {
      dispatch(updateLocalAuthorization({}));
      dispatch(resetDrawer());
    },
  };
  return (
    <Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={authorizationClone?.organizationId}
        />
      </Box>
      <Box mb={5}>
        <InputComponent
          label="Name"
          placeholder="Auth Endpoint"
          value={authorizationClone?.name}
          onChange={formActions.onAuthNameChange}
        />
      </Box>
      <Box mb={5}>
        <AuthEndpointSelection
          organizationId={authorizationClone?.organizationId}
          onChange={formActions.onEndpointChange}
          value={authorizationClone?.endpointId}
        />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        {_.isEmpty(authorizationClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingAuthorization}
            loadingText="Creating Auth..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                createAuthorization(
                  authorizationClone,
                  formActions.onAuthorizationCreated
                )
              );
            }}
          >
            Create Authorization
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingAuthorization}
            loadingText="Updating Auth..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                updateAuthorization(
                  authorizationClone,
                  formActions.onAuthorizationCreated
                )
              );
            }}
          >
            Update Auth
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AuthorizationForm;
