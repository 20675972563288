import { Box, HStack, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { updateDrawer } from "../../store/actions/miscAction";
import { updateLocalSection } from "../../store/actions/sectionsActions";
const Actions = ({ section }) => {
  const { id } = section;
  const dispatch = useDispatch();
  const openDeleteSection = () => {
    dispatch(updateLocalSection(section));
    const drawer = {
      title: "Delete Section",
      isOpen: 1,
      content: { id, component: "DeleteSectionForm" },
    };
    dispatch(updateDrawer(drawer));
  };

  return (
    <Box>
      <HStack>
        <Text
          mr={2}
          colorScheme="blue"
          color="var(--black)"
          cursor="pointer"
          aria-label="Edit"
          icon={<EditIcon />}
          onClick={() => {
            dispatch(updateLocalSection(section));
            const drawer = {
              title: "Update Module Section",
              isOpen: 1,
              content: { id, component: "ModuleSectionForm" },
            };
            dispatch(updateDrawer(drawer));
          }}
        >
          Edit Section
        </Text>
        <Text
          color="var(--black)"
          cursor="pointer"
          onClick={() => {
            openDeleteSection();
          }}
          aria-label="Delete"
          icon={<DeleteIcon />}
          colorScheme="red"
        >
          Delete
        </Text>
      </HStack>
    </Box>
  );
};

export default Actions;
