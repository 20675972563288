import { toast } from "react-toastify";
import io from "socket.io-client";
import { DELETEData, GETData, PATCHData, POSTData } from "./events";
import { ACTION_TYPES } from "./types";
import store from "../index";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      dispatch({
        type,
        payload,
      });
    });
  };

export const listenStylings = () =>
  listenToEvent("admin_styling_ss", "LISTEN_STYLINGS", 1);

export const fetchStylings = () => async (dispatch) => {
  try {
    console.log("payload", "payload");
    const endpoint = "/admin/styling/ss";
    dispatch(GETData(endpoint, null, false, ACTION_TYPES.FETCH_STYLINGS));
  } catch (err) {
    console.error("Error fetching stylings:", err);
    throw err;
  }
};

export const createStyling = (data, onStylingCreated, stylings) => async (dispatch) => {
  if (!data.organizationId) {
    toast.error("Please fill out all required fields");
    return;
  }
  if(stylings.some(a => a.organizationId === data.organizationId)) {
    toast.error("Styling for this organization already exists");
    return;
  }
  dispatch(updateCreatingStyling(true));
  try {
    const endpoint = "/admin/styling/ss";
    await dispatch(POSTData(endpoint, data, ACTION_TYPES.ADD_MODULE));
  } catch (err) {
    console.error("Error adding styling:", err);
    toast.error("Error adding styling");
    throw err;
  }

  dispatch(updateCreatingStyling(false));
  onStylingCreated();
};

export const updateStyling = (data, onStylingCreated) => async (dispatch) => {
  if (!data.organizationId) {
    toast.error("Please fill out all required fields");
    return;
  }
  dispatch(updateCreatingStyling(true));
  try {
    const endpoint = "/admin/styling/ss";
    await dispatch(PATCHData(endpoint, data, ACTION_TYPES.ADD_MODULE));
  } catch (err) {
    console.error("Error updating styling:", err);
    toast.error("Error updating styling");
    throw err;
  }

  dispatch(updateCreatingStyling(false));
  onStylingCreated();
};

export const deleteStyling = (id, onStylingDeleted) => async (dispatch) => {
  try {
    const endpoint = "/admin/styling/ss";
    await dispatch(DELETEData(endpoint, id, ACTION_TYPES.ADD_MODULE));
  } catch (err) {
    console.error("Error deleting styling:", err);
    toast.error("Error deleting styling");
    throw err;
  }

  onStylingDeleted();
};

export const setStylingsLoading = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_STYLINGS_LOADING,
    payload: payload,
  });
};

// UPDATE_LOCAL_STYLING
export const updateLocalStyling = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_STYLING,
    payload: payload,
  });
};

// UPDATE_CREATING_STYLING
export const updateCreatingStyling = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CREATING_STYLING,
    payload: payload,
  });
};
