import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";

const MethodSelection = ({ onChange, value }) => {
  const options = [
    { name: "GET", id: "GET"},
    { name: "POST", id: "POST" },
    { name: "PUT", id: "PUT" },
    { name: "DELETE", id: "DELETE" },
    { name: "PATCH", id: "PATCH" },

  ];
  return (
    <Box>
      <Select
        label="Endpoint Method"
        placeholder="Select the Endpoint Method"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default MethodSelection;