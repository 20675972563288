import { Box } from "@chakra-ui/react";
import FilterForm from "../components/FilterForm";
import { ACTION_BUTTONS } from "../store/constants";
import SubscriptionsComponent from "../components/subscriptions/SubscriptionsComponent";

const ModulesSubscriptions = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? <FilterForm actionButton={ACTION_BUTTONS.CREATE_SUBSCRIPTION}/> : null}
      <SubscriptionsComponent/>
    </Box>
  );
};

export default ModulesSubscriptions;
