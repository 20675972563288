import { Box, VStack, Icon, Link, Text } from "@chakra-ui/react";
import _ from "lodash";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import EndpointsTable from "./EndpointsTable";
import { updateDrawer } from "../../store/actions/miscAction";
import { updateLocalAdminEndpoint } from "../../store/actions/adminEndpointsActions";

const AdminEndpointsComponent = () => {
  const dispatch = useDispatch();
  const { adminEndpoints } = useSelector(
    (state) => state.adminEndpointsReducer
  );
  const { organizations, organizationId } = useSelector(
    (state) => state.defaultReducer
  );


  let endpointsClone = _.cloneDeep(adminEndpoints);
  if (organizationId.length > 0) {
    endpointsClone = endpointsClone.filter(
      (a) => a.organizationId === organizationId
    );
  }

  const orgsMap = endpointsClone.reduce((acc, curr) => {
    if (!acc[curr.organizationId]) {
      acc[curr.organizationId] = [];
    }
    acc[curr.organizationId].push(curr);
    return acc;
  }, {});
  const orgsData = Object.keys(orgsMap).map((organizationId) => {
    const orgEndpoints = orgsMap[organizationId];

    return {
      orgEndpoints,
      organizationId,
      name:
        organizations.find((a) => a.id === organizationId)?.name ||
        "Unnamed Org",
    };
  });

  const openDrawer = () => {
    dispatch(updateLocalAdminEndpoint({}));
    const drawer = {
      title: "Create AdminEndpoint",
      isOpen: 1,
      content: { component: "AdminEndpointForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };
  return (
    <Box>
      {orgsData.length > 0 ? (
        orgsData.map((a, i) => (
          <EndpointsTable data={a} key={a.organizationId} />
        ))
      ) : (
        <VStack mt="100px">
          <Box w="128px" h="128px">
            <Icon
              as={IoCloseCircleOutline}
              boxSize="128px"
              color="var(--red-pale)"
            />
          </Box>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            No endpoints created for the current selection.
          </Text>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            <Link color="var(--blue)" onClick={openDrawer}>
              Create an endpoint
            </Link>{" "}
            to get started.
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default AdminEndpointsComponent;
