import { ACTION_TYPES } from "../actions/types";

const initialState = {
  authorizations: [],
  authorizationsLoading: false,
  creatingAuthorization: false,
  listenAuthorizationsStart: 0,
  authorization: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_AUTHORIZATIONS:
      return {
        ...state,
        authorizations: action.payload,
      };

    case ACTION_TYPES.LISTEN_AUTHORIZATIONS_START:
      return {
        ...state,
        listenAuthorizationsStart: action.payload,
      };

    case ACTION_TYPES.LISTEN_AUTHORIZATIONS_CREATE:
      return {
        ...state,
        authorizations: [...state.authorizations, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_AUTHORIZATIONS_UPDATE:
      return {
        ...state,
        authorizations: state.authorizations.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_AUTHORIZATIONS_DELETE:
      return {
        ...state,
        authorizations: state.authorizations.filter((data) => data.id !== action.payload.id),
      };

    case ACTION_TYPES.SET_AUTHORIZATIONS_LOADING:
      return {
        ...state,
        authorizationsLoading: action.payload,
      };

    case ACTION_TYPES.UPDATE_LOCAL_AUTHORIZATION:
      return {
        ...state,
        authorization: action.payload,
      };

    case ACTION_TYPES.UPDATE_CREATING_AUTHORIZATION:
      return {
        ...state,
        creatingAuthorization: action.payload,
      };
    default:
      return state;
  }
}
