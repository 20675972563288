import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import {
  getAudiences,
  getBranches,
  getOrganizations,
  getUsers,
} from "./store/actions/defaultAction";

// import actions
import { updateAppStatus, verifyToken } from "./store/actions/authActions";
import { getIamRoles } from "./store/actions/permissionsActions";

import usePermissions from "./hooks/usePermissions";

// import components
import RoutesComponent from "./Routes";
import NoPermissions from "./pages/NoPermissions";
import Loader from "./components/loader";

// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";
import "./assets/css/Inputs.css";
import { fetchModules, listenModules } from "./store/actions/modulesActions";
import {
  fetchEndpoints,
  listenEndpoints,
} from "./store/actions/endpointsActions";
import { fetchSections, listenSections } from "./store/actions/sectionsActions";
import { fetchSubscriptions, listenSubscriptions } from "./store/actions/subscriptionsActions";
import { fetchAuthorizations, listenAuthorizations } from "./store/actions/authorizationActions";
import { fetchAdminEndpoints, listenAdminEndpoints } from "./store/actions/adminEndpointsActions";
import { fetchStylings, listenStylings } from "./store/actions/stylingsActions";

function App() {
  const { read } = usePermissions();
  const dispatch = useDispatch();
  const { appStatus, userId, verifyStatus, userData } = useSelector(
    (state) => state.authReducer
  );
  const { organizationId } = useSelector((state) => state.defaultReducer);

  useEffect(() => {
    dispatch(verifyToken());
  }, []);
  useEffect(() => {
    if (userId && verifyStatus === 1) {
      dispatch(getIamRoles());
    }
  }, [userId, verifyStatus]);
  useEffect(() => {
    if (appStatus) {
      dispatch(getOrganizations());
      dispatch(getBranches());
      dispatch(getAudiences())
      dispatch(fetchModules());
      dispatch(fetchEndpoints());
      dispatch(fetchSections());
      dispatch(fetchSubscriptions())
      dispatch(fetchAuthorizations());
      dispatch(fetchAdminEndpoints())
      dispatch(fetchStylings())

      //sockets
      dispatch(listenModules());
      dispatch(listenEndpoints());
      dispatch(listenSections());
      dispatch(listenSubscriptions());
      dispatch(listenAuthorizations());
      dispatch(listenAdminEndpoints());
      dispatch(listenStylings())
    }
  }, [appStatus]);

  useEffect(() => {
    if (organizationId.length > 0) {
      dispatch(getUsers(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    if (read && verifyStatus === 1) {
      dispatch(updateAppStatus(1));
    } else {
      dispatch(updateAppStatus(0));
    }
  }, [read, verifyStatus]);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });

  const permissionsCheck =
    !read && !_.isEmpty(userData) ? <NoPermissions /> : <Loader />;

  return (
    <ChakraProvider theme={theme}>
      {!appStatus ? (
        permissionsCheck
      ) : (
        <Router>
          <RoutesComponent />
        </Router>
      )}
    </ChakraProvider>
  );
}
export default App;
