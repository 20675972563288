import { Box } from "@chakra-ui/react";
import FilterForm from "../components/FilterForm";
import EndpointsComponent from "../components/endpoints/EndpointsComponent";
import { ACTION_BUTTONS } from "../store/constants";

const Endpoints = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? <FilterForm actionButton={ACTION_BUTTONS.CREATE_ENDPOINT} /> : null}
      <EndpointsComponent/>
    </Box>
  );
};

export default Endpoints;
