import { Alert, Box, Button, Stack } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer, updateDrawer } from "../../../store/actions/miscAction";
import ModuleEndpointsSelection from "../subscriptionForm/ModuleEndpointsSelection";
import {
  createSubscription,
  updateLocalSubscription,
  updateSubscription,
} from "../../../store/actions/subscriptionsActions";

const ModuleEndpointsForm = () => {
  const dispatch = useDispatch();
  const { subscription, creatingSubscription } = useSelector(
    (store) => store.subscriptionsReducer
  );
  const { sections } = useSelector((store) => store.sectionsReducer);
  const subscriptionClone = _.cloneDeep(subscription);
  const sectionsClone = _.cloneDeep(sections);

  const onBack = () => {
    const drawer = {
      title: subscriptionClone?.id
        ? "Update Subscription"
        : "Create Subscription",
      isOpen: 1,
      content: { component: "SubscriptionForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };

  const moduleSections = sectionsClone.filter(
    (a) => a.moduleId === subscriptionClone.moduleId
  );

  const onSubscriptionCreated = () => {
    dispatch(resetDrawer());
    dispatch(updateLocalSubscription({}));
  };
  return (
    <Box>
      <Box mb={5}>
        {moduleSections.length === 0 ? (
          <Box>
            <Alert status="warning" borderRadius={5}>
              No sections found for this module. Please create a section first.
            </Alert>
          </Box>
        ) : (
          moduleSections.map((a) => (
            <ModuleEndpointsSelection key={a.id} sectionId={a.id} />
          ))
        )}
      </Box>
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        direction={"row"}
      >
        <Button w="150px" h="56px" borderRadius="8px" onClick={onBack}>
          Back
        </Button>

        {_.isEmpty(subscriptionClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingSubscription}
            loadingText="Creating Subscription..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                createSubscription(subscriptionClone, onSubscriptionCreated)
              );
            }}
          >
            Create Subscription
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingSubscription}
            loadingText="Updating Section..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                updateSubscription(subscriptionClone, onSubscriptionCreated)
              );
            }}
          >
            Update Subscription
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ModuleEndpointsForm;
