import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";

const AuthorizationSelection = ({ onChange, value }) => {
  const options = [
    { name: "No Auth", id: "No Auth"},
    { name: "Basic Auth", id: "Basic Auth" },
    { name: "Bearer Token", id: "Bearer Token" }
  ];
  return (
    <Box>
      <Select
        label="Authorization"
        placeholder="Select Authorization Type"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default AuthorizationSelection;