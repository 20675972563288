import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";

const IconSetSelection = ({ onChange, value }) => {
  const options = [
    {
      name: "io5",
      id: "io5",
    },
    {
      name: "md",
      id: "md",
    },
    {
      name: "fa",
      id: "fa",
    },
  ];
  return (
    <Box>
      <Select
        label="Icon Name"
        placeholder="Select Icon Name"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default IconSetSelection;
