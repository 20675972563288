import { Box, Button } from "@chakra-ui/react";
import ModuleSelection from "../moduleSectionForm/ModuleSelection";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  createSection,
  updateLocalSection,
  updateSection,
} from "../../../store/actions/sectionsActions";
import InputComponent from "../../averly/Input";
import { resetDrawer } from "../../../store/actions/miscAction";

const ModuleSectionForm = () => {
  const dispatch = useDispatch();
  const { section, creatingSection } = useSelector(
    (store) => store.sectionsReducer
  );
  const sectionClone = _.cloneDeep(section);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(updateLocalSection({ ...sectionClone, organizationId }));
    },
    onModuleChange: (moduleId) => {
      dispatch(updateLocalSection({ ...sectionClone, moduleId }));
    },
    onNameChange: (name) => {
      dispatch(updateLocalSection({ ...sectionClone, name }));
    },

    onSectionCreated: () => {
      dispatch(updateLocalSection({}));
      dispatch(resetDrawer());
    },
  };
  return (
    <Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={sectionClone?.organizationId || ""}
        />
      </Box>

      <Box mb={5}>
        <ModuleSelection
          organizationId={sectionClone?.organizationId}
          onChange={formActions.onModuleChange}
          value={sectionClone?.moduleId}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Name"
          placeholder="History"
          value={sectionClone?.name}
          onChange={formActions.onNameChange}
        />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        {_.isEmpty(sectionClone?.id) ? (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingSection}
            loadingText="Creating Section..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                createSection(sectionClone, formActions.onSectionCreated)
              );
            }}
          >
            Create Section
          </Button>
        ) : (
          <Button
            w="200px"
            h="56px"
            isLoading={creatingSection}
            loadingText="Updating Section..."
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(
                updateSection(sectionClone, formActions.onSectionCreated)
              );
            }}
          >
            Update Section
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ModuleSectionForm;
