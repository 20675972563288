import { combineReducers } from "redux";
import defaultReducer from "./defaultReducer";
import authReducer from "./authReducer";
import miscReducer from "./miscReducer";
import permissionsReducer from "./permissionsReducer";
import modulesReducer from "./modulesReducer";  
import endpointsReducer from "./endpointsReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import sectionsReducer from "./sectionsReducer"
import authorizationsReducer from "./authorizationsReducer";
import adminEndpointsReducer from "./adminEndpointsReducer";
import stylingsReducer from "./stylingsReducer";

const reducer = combineReducers({
  defaultReducer,
  authReducer,
  miscReducer,
  permissionsReducer,
  modulesReducer,
  endpointsReducer,
  subscriptionsReducer,
  sectionsReducer,
  authorizationsReducer,
  adminEndpointsReducer,
  stylingsReducer
});

export default reducer;
