import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Center,
  chakra,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Actions from "./Actions";
import { useSelector } from "react-redux";

const StylingTable = ({ data }) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const { organizations } = useSelector((a) => a.defaultReducer);
  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  return (
    <TableContainer>
      <Center m={1}>
        <chakra.h1
          textAlign={"center"}
          fontSize={"4xl"}
          py={5}
          fontWeight={"bold"}
        >
          {"Styling and Content"}
        </chakra.h1>
      </Center>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Organization</Th>
            <Th>System</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((a) => {
            console.log(a, 'a');
            return (
              <Tr key={a.id}>
                <Td>
                  {
                    organizations.find(
                      (b) => b.id === a.organizationId
                    )?.name
                  }
                </Td>
                <Td>
                  {Number(a.system) === 1
                    ? "All Organizations"
                    : "Single Organization"}
                </Td>
                <Td>{ <Actions styling={a} /> }</Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Organization</Th>
            <Th>System</Th>
            <Th>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default StylingTable;
