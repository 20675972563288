import { toast } from "react-toastify";
import io from "socket.io-client";
import { DELETEData, GETData, PATCHData, POSTData } from "./events";
import { ACTION_TYPES } from "./types";
import store from "../index";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      // dispatch only if the payload is for the active organization
      dispatch({
        type,
        payload,
      });
    });
  };

export const listenEndpoints = () =>
  listenToEvent("apps_ss_endpoints", "LISTEN_ENDPOINTS", 0);

export const fetchEndpoints = () => async (dispatch) => {
  try {
    console.log("payload", "payload");
    const endpoint = "/apps/ss/endpoints";
    dispatch(GETData(endpoint, null, false, ACTION_TYPES.FETCH_ENDPOINTS));
  } catch (err) {
    console.error("Error fetching endpoints:", err);
    throw err;
  }
};

export const createEndpoint = (data, onEndpointCreated) => async (dispatch) => {
  if (
    !data.name ||
    !data.server ||
    !data.port ||
    !data.protocol ||
    !data.organizationId ||
    !data.responseCodes ||
    !data.method ||
    !data.contentType
  ) {
    toast.error("Please fill out all required fields");
    return;
  }
  dispatch(updateCreatingEndpoint(true));
  try {
    const endpoint = "/apps/ss/endpoints";
    await dispatch(POSTData(endpoint, data, ACTION_TYPES.ADD_ENDPOINT));
  } catch (err) {
    console.error("Error adding endpoint:", err);
    toast.error("Error adding endpoint");
    throw err;
  }

  dispatch(updateCreatingEndpoint(false));
  onEndpointCreated();
};

export const updateEndpoint = (data, onEndpointCreated) => async (dispatch) => {
  if (
    !data.name ||
    !data.server ||
    !data.port ||
    !data.protocol ||
    !data.organizationId ||
    !data.responseCodes ||
    !data.method ||
    !data.contentType
  ) {
    toast.error("Please fill out all required fields");
    return;
  }
  dispatch(updateCreatingEndpoint(true));
  try {
    const endpoint = "/apps/ss/endpoints";
    await dispatch(PATCHData(endpoint, data, ACTION_TYPES.ADD_ENDPOINT));
  } catch (err) {
    console.error("Error updating endpoint:", err);
    toast.error("Error updating endpoint");
    throw err;
  }

  dispatch(updateCreatingEndpoint(false));
  onEndpointCreated();
};

export const deleteEndpoint = (id, onEndpointDeleted) => async (dispatch) => {
  try {
    const endpoint = "/apps/ss/endpoints";
    await dispatch(DELETEData(endpoint, id, ACTION_TYPES.ADD_ENDPOINT));
  } catch (err) {
    console.error("Error deleting endpoint:", err);
    toast.error("Error deleteting endpoint");
    throw err;
  }

  onEndpointDeleted();
};

export const setEndpointsLoading = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENDPOINTS_LOADING,
    payload: payload,
  });
};

// UPDATE_LOCAL_ENDPOINT
export const updateLocalEndpoint = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_ENDPOINT,
    payload: payload,
  });
};

// UPDATE_CREATING_ENDPOINT
export const updateCreatingEndpoint = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CREATING_ENDPOINT,
    payload: payload,
  });
};
