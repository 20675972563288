import { ACTION_TYPES } from "../actions/types";

const initialState = {
  modules: [],
  modulesLoading: false,
  creatingModule: false,
  listenModulesStart: 0,
  module: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MODULES:
      return {
        ...state,
        modules: action.payload,
      };

    case ACTION_TYPES.LISTEN_MODULES_START:
      return {
        ...state,
        listenModulesStart: action.payload,
      };

    case ACTION_TYPES.LISTEN_MODULES_CREATE:
      return {
        ...state,
        modules: [...state.modules, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_MODULES_UPDATE:
      return {
        ...state,
        modules: state.modules.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_MODULES_DELETE:
      return {
        ...state,
        modules: state.modules.filter((data) => data.id !== action.payload.id),
      };

    case ACTION_TYPES.SET_MODULES_LOADING:
      return {
        ...state,
        modulesLoading: action.payload,
      };

    case ACTION_TYPES.UPDATE_LOCAL_MODULE:
      return {
        ...state,
        module: action.payload,
      };

    case ACTION_TYPES.UPDATE_CREATING_MODULE:
      return {
        ...state,
        creatingModule: action.payload,
      };
    default:
      return state;
  }
}
