import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";

const RawDataTypeSelection = ({ onChange, value }) => {
  const options = [
    { name: "Text", id: "text"},
    { name: "JavaScript", id: "JavaScript" },
    { name: "JSON", id: "JSON" },
    { name: "HTML", id: "HTML" },
    { name: "XML", id: "XML" },
  ];
  return (
    <Box>
      <Select
        label="Raw Body Data Type"
        placeholder="Select the Raw Body Data Type"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default RawDataTypeSelection;