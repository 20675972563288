import { ACTION_TYPES } from "../actions/types";

const initialState = {
  stylings: [],
  stylingsLoading: false,
  creatingStyling: false,
  listenStylingsStart: 0,
  styling: {},
};

export default function stylingsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_STYLINGS:
      return {
        ...state,
        stylings: action.payload,
      };

    case ACTION_TYPES.LISTEN_STYLINGS_START:
      return {
        ...state,
        listenStylingsStart: action.payload,
      };

    case ACTION_TYPES.LISTEN_STYLINGS_CREATE:
      return {
        ...state,
        stylings: [...state.stylings, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_STYLINGS_UPDATE:
      return {
        ...state,
        stylings: state.stylings.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_STYLINGS_DELETE:
      return {
        ...state,
        stylings: state.stylings.filter((data) => data.id !== action.payload.id),
      };

    case ACTION_TYPES.SET_STYLINGS_LOADING:
      return {
        ...state,
        stylingsLoading: action.payload,
      };

    case ACTION_TYPES.UPDATE_LOCAL_STYLING:
      return {
        ...state,
        styling: action.payload,
      };

    case ACTION_TYPES.UPDATE_CREATING_STYLING:
      return {
        ...state,
        creatingStyling: action.payload,
      };
    default:
      return state;
  }
}
