import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer } from "../../../store/actions/miscAction";
import { useState } from "react";
import { deleteStyling, updateLocalStyling } from "../../../store/actions/stylingsActions";

const DeleteStylingForm = () => {
  const dispatch = useDispatch();
  const { styling } = useSelector((state) => state.stylingsReducer);
  const stylingClone = _.cloneDeep(styling);
  const { system } = stylingClone;
  const [deleteString, setDeleteString] = useState("");

  const onStylingDeleted = ()=>{
    dispatch(updateLocalStyling({}));
    dispatch(resetDrawer());
  }
  return (
    <Box>
      <Box bgColor={"#F8F3D9"} borderRadius={6}>
        <Text p={5} color={"gray.500"}>
          Deleting a styling is permanent. Please make sure you are deleting the
          correct module.
        </Text>
      </Box>

      <Box>
        <HStack mt={5}>
          <Text color={"gray.500"} fontWeight={"bold"}>
            System:
          </Text>
          <Text>{Number(system) === 1 ? "All Organizations" : "Single Organization"}</Text>
        </HStack>

        <Divider mt={5} />

        <Box>
          <HStack>
            <Text
              alignSelf="left"
              color="gray.600"
              fontWeight="bold"
              whiteSpace="nowrap"
              fontSize={14}
            >
              Type delete to delete this styling
            </Text>
            <Text as="span" color="red" mt={2}>
              *
            </Text>
          </HStack>
          <Input
            placeholder="type"
            value={deleteString}
            onChange={(e) => setDeleteString(e.target.value)}
          />
        </Box>

        <Divider mt={5} />

        <ButtonGroup mt={10}>
          <Button onClick={() => dispatch(resetDrawer())}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={()=>dispatch(deleteStyling(stylingClone.id, onStylingDeleted))}
            loadingText={"Deleting styling..."}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeleteStylingForm;
