import { Box, Text } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import MultiSelect from "react-select";
import { MODULE_ENDPOINTS_TYPES } from "../../../../store/constants";

const ModuleEndpointsSelection = ({
  onChange,
  sectionId,
  moduleEndPointType,
  label,
}) => {
  const { endpoints } = useSelector((store) => store.endpointsReducer);
  const { subscription } = useSelector((store) => store.subscriptionsReducer);
  const endpointsClone = _.cloneDeep(endpoints);
  const subscriptionClone = _.cloneDeep(subscription);

  const endpointsData = endpointsClone
    .filter((a) => a.organizationId === subscriptionClone.organizationId)
    .map((a) => {
      return {
        label: `${a.name} - ${a.method} - ${a.endpoint}`,
        value: a.id,
      };
    });

  let defaultSelected = null;
  switch (moduleEndPointType) {
    case MODULE_ENDPOINTS_TYPES.MODULE_ON_MOUNT:
      defaultSelected = subscriptionClone?.moduleSections?.find(
        (a) => a.sectionId === sectionId
      )?.moduleOnMountEndpoints;
      break;
    case MODULE_ENDPOINTS_TYPES.INITIAL_LOAD:
      defaultSelected = subscriptionClone?.moduleSections?.find(
        (a) => a.sectionId === sectionId
      )?.initialLoadEndpoints;
      break;
    case MODULE_ENDPOINTS_TYPES.SUBMIT:
      defaultSelected = subscriptionClone?.moduleSections?.find(
        (a) => a.sectionId === sectionId
      )?.submitEndpoint;
      break;

    case MODULE_ENDPOINTS_TYPES.REFRESH_ENDPOINTS:
      defaultSelected = subscriptionClone?.moduleSections?.find(
        (a) => a.sectionId === sectionId
      )?.refreshEndpoints;
  }

  return (
    <Box
      mt="10px"
      w="100%"
      h="auto"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 5px 9px 12px"
    >
      <Text h="15px" lineHeight="15px" fontSize="12px" color="#6a6a6a">
        {label}
      </Text>

      <MultiSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            outline: "none",
            boxShadow: "none",
            paddingLeft: 0,
            marginLeft: 0,
          }),
        }}
        options={endpointsData.map((a) => ({
          value: a.value,
          label: a.label,
        }))}
        defaultValue={
          moduleEndPointType === MODULE_ENDPOINTS_TYPES.SUBMIT
            ? { value: defaultSelected, label: defaultSelected }
            : defaultSelected?.map((a) => {
                const endpoint = endpointsClone.find((b) => b.id === a);

                return {
                  value: a,
                  label: `${endpoint.name} - ${endpoint.method} - ${endpoint.endpoint}`,
                };
              })
        }
        onChange={(e) => {
          const newValue =
            moduleEndPointType === MODULE_ENDPOINTS_TYPES.SUBMIT
              ? e?.value || ""
              : e.map((a) => a.value);
          onChange(newValue);
        }}
        isMulti={moduleEndPointType !== MODULE_ENDPOINTS_TYPES.SUBMIT}
        isClearable={true}
        isSearchable={true}
        placeholder="Select Endpoints"
      />
    </Box>
  );
};

export default ModuleEndpointsSelection;
