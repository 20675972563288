import { Box, Icon, Link, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { updateDrawer } from "../../store/actions/miscAction";
import ModuleSectionsTable from "./ModuleSectionsTable";
import { updateLocalSection } from "../../store/actions/sectionsActions";

const SectionsComponent = () => {
  const dispatch = useDispatch();
  const { sections } = useSelector((state) => state.sectionsReducer);
  const { organizations, organizationId, moduleId } = useSelector(
    (state) => state.defaultReducer
  );
  const { modules } = useSelector((state) => state.modulesReducer);

  let modulesClone = _.cloneDeep(modules);
  let sectionsClone = _.cloneDeep(sections).filter(
    (a) => !moduleId?.length || (moduleId?.length && a.moduleId === moduleId)
  );
  if (organizationId.length > 0) {
    sectionsClone = sectionsClone.filter(
      (a) => a.organizationId === organizationId
    );
  }

  const orgsMap = sectionsClone.reduce((acc, curr) => {
    if (!acc[curr.organizationId]) {
      acc[curr.organizationId] = [];
    }

    const moduleName = modulesClone.find((a) => a.id === curr.moduleId)?.name;
    acc[curr.organizationId].push({ ...curr, moduleName });
    return acc;
  }, {});
  const orgsData = Object.keys(orgsMap).map((organizationId) => {
    const orgSections = orgsMap[organizationId];

    return {
      orgSections,
      organizationId,
      name:
        organizations.find((a) => a.id === organizationId)?.name ||
        "Unnamed Org",
    };
  });

  const openDrawer = () => {
    dispatch(updateLocalSection({}));
    const drawer = {
      title: "Create Module Section",
      isOpen: 1,
      content: { component: "ModuleSectionForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };
  return (
    <Box>
      {orgsData.length > 0 ? (
        orgsData.map((a, i) => (
          <ModuleSectionsTable data={a} key={a.organizationId} />
        ))
      ) : (
        <VStack mt="100px">
          <Box w="128px" h="128px">
            <Icon
              as={IoCloseCircleOutline}
              boxSize="128px"
              color="var(--red-pale)"
            />
          </Box>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            No sections created for the current selection.
          </Text>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            <Link color="var(--blue)" onClick={openDrawer}>
              Create a section
            </Link>{" "}
            to get started.
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default SectionsComponent;
