import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import Select from "../../averly/Select";

const ModuleSelection = ({ organizationId, value, onChange }) => {
  const { modules } = useSelector((store) => store.modulesReducer);
  const modulesClone = _.cloneDeep(modules);
  // Only one sign In subscription allowed
  const orgModules = modulesClone.filter(
    (a) => a.organizationId === organizationId || Number(a.system) === 1
  );

  return (
    <Box>
      <Box>
        <Select
          label="Subscription Module"
          placeholder="Select Module"
          value={value}
          options={orgModules}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default ModuleSelection;
