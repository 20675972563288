import axios from "axios";
import dayjs from "dayjs";
import io from "socket.io-client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACTION_TYPES } from "./types";
import store from "../index";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
export const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      // dispatch only if the payload is for the active organization
      let organizationId;
      if (organizationCheck) {
        const { userData: authenticatedUser } = store.getState().authReducer;
        organizationId =
          authenticatedUser.activeOrganization &&
          authenticatedUser.activeOrganization.length
            ? authenticatedUser.activeOrganization
            : authenticatedUser.organizationId;
      }

      if (!organizationCheck || payload.organizationId === organizationId) {
        dispatch({
          type,
          payload,
        });
      }
    });
  };

export const fetchData = async (endpoint, params, singleResponse, dispatch, actionType) => {
  try {
    const res = await axios.get(endpoint, { params });
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;
    dispatch({ type: actionType, payload });
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    throw err;
  }
};


// GET_ORGANIZATIONS
export const getOrganizations = (organizationId) => async (dispatch) => {
  const endpoint = "/admin/organizations/";
  const payload = {
    organizationId,
  };
  const actionType = "GET_ORGANIZATIONS";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// GET_BRANCHES
export const getBranches = () => async (dispatch) => {
  const endpoint = "/admin/branches/";
  const payload = {
    
  };
  const actionType = "GET_BRANCHES";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// GET_SERVICES
export const getServices = (organizationId, branchId) => async (dispatch) => {
  const endpoint = "/apps/qm/services/";
  const payload = {
    organizationId,
    branchId,
  };
  const actionType = "GET_SERVICES";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};
// GET_SERVICES_SUBSCRIPTIONS
export const getServicesSubscriptions =
  (organizationId, branchId) => async (dispatch) => {
    const endpoint = "/apps/qm/serviceSubscriptions/";
    const payload = {
      organizationId,
      branchId,
    };
    const actionType = "GET_SERVICES_SUBSCRIPTIONS";
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };
// GET_USERS
export const getUsers =
  (organizationId, branchId, dateRange = null) =>
  async (dispatch) => {
    const endpoint = "/iam/users/";
    const payload = {
      organizationId,
      branchId,
    };
    const actionType = "GET_USERS";
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

// search tickets
export const setDateRange = (dateRange) => async (dispatch) => {
  console.log("dateRange", dateRange);
  dispatch({
    type: "SET_DATE_RANGE",
    payload: dateRange,
  });
};

export const updateReportRange = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_REPORT_RANGE",
    payload,
  });
  // do reset
  dispatch(getBranches(payload));
  dispatch(setDateRange(""));
};

export const updateOrganizationId = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_ORGANIZATION_ID",
    payload,
  });

  dispatch(getBranches(payload));

  // do reset
  dispatch(setDateRange(""));
  dispatch({ type: ACTION_TYPES.GET_REPORTS_DATA, payload: [] });
  dispatch({ type: "GET_BRANCHES", payload: [] });
  dispatch({ type: "UPDATE_BRANCH_ID", payload: "" });
};

export const updateBranchId =
  ({ branchId, organizationId }) =>
  (dispatch) => {
    dispatch({
      type: "UPDATE_BRANCH_ID",
      payload: branchId,
    });
    dispatch(getBranches(organizationId, branchId));
  };



//UPDATE_MODULE_ID
export const updateModuleId = (moduleId) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_MODULE_ID,
    payload: moduleId,
  });
};


// GET_AUDIENCES
export const getAudiences = () => async (dispatch) => {
  const endpoint = "/admin/audience/";
  const payload = {
    
  };
  const actionType = ACTION_TYPES.GET_AUDIENCES;
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};