import { Box, Icon, Link, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { updateDrawer } from "../../store/actions/miscAction";
import StylingTable from "./StylingTable";
import { updateLocalStyling } from "../../store/actions/stylingsActions";

const StylingComponent = () => {
  const dispatch = useDispatch();
  const { stylings } = useSelector((state) => state.stylingsReducer);
  const { organizations, organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  let stylingsClone = _.cloneDeep(stylings);
  const systemModules = stylingsClone.filter((a) => Number(a.system) === 1); //sysModules have no organizationId
  if (organizationId.length > 0) {
    stylingsClone = stylingsClone.filter(
      (a) => a.organizationId === organizationId
    );
  }

  

  const openDrawer = () => {
    dispatch(updateLocalStyling({}));
    const drawer = {
      title: "Create Styling",
      isOpen: 1,
      content: { component: "StylingForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };

  console.log(stylingsClone)
  return (
    <Box>
      {stylingsClone.length > 0 ? (
        <StylingTable data={stylingsClone} />
      ) : (
        <VStack mt="100px">
          <Box w="128px" h="128px">
            <Icon
              as={IoCloseCircleOutline}
              boxSize="128px"
              color="var(--red-pale)"
            />
          </Box>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            No stylings created for the current selection.
          </Text>
          <Text fontSize="16px" color="#666" fontWeight={400} mt="0px">
            <Link color="var(--blue)" onClick={openDrawer}>
              Create Styling
            </Link>{" "}
            to get started.
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default StylingComponent;
