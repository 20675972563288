import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";

const ContentTypeSelection = ({ onChange, value }) => {
  const options = [
    { name: "text/plain", id: "text/plain"},
    { name: "application/json", id: "application/json" },
    { name: "application/xml", id: "application/xml" },
    { name: "application/x-www-form-urlencoded", id: "application/x-www-form-urlencoded" },
    { name: "multipart/form-data", id: "multipart/form-data" },
    { name: "application/octet-stream", id: "application/octet-stream" },
    { name: "application/pdf", id: "application/pdf" },
  ];
  return (
    <Box>
      <Select
        label="Endpoint Content Type"
        placeholder="Select the Endpoint Content Type"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default ContentTypeSelection;