import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";
import { BODY_OPTIONS } from "../../../store/constants";

const BodyTypeSelection = ({ onChange, value }) => {

  const options = Object.values(BODY_OPTIONS).map((a)=>{
    return {
        id: a,
        name: a
    }
  })
  return (
    <Box>
      <Select
        label="Endpoint Body Type"
        placeholder="Select the Endpoint Body Type"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default BodyTypeSelection;