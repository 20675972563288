import axios from 'axios';
import { toast } from 'react-toastify';
import io from 'socket.io-client';

const api = 'https://cx.api.averly.com.na';

const socketOptions = {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 5, // Number of reconnection attempts
  reconnectionDelay: 1000, // Delay between reconnections in ms
};

// Declare a variable to hold the Socket.IO client instance
const socket = {}; 

export const listenToEvent = (eventName, updateType) => (dispatch) => {
    socket[eventName] = io(api, {
    ...socketOptions,
    // auth: { token: 'authToken' },
    });

  socket[eventName].on(`${eventName}-updated`, (res) => {
    console.log(updateType, "event", res);

    const { new_val, old_val } = res;
    let type, payload;

    if (new_val !== null) {
      type = `${updateType}${old_val !== null ? '_UPDATE' : '_CREATE'}`;
      payload = new_val;
    } else {
      type = `${updateType}_DELETE`;
      payload = old_val;
    }

    dispatch({ type, payload });
  });

  socket[eventName].on('connect', () => {
    console.log(`Connected to ${eventName}`);
    dispatch({ type: `${updateType}_START`, payload: 1 });
  });

  socket[eventName].on('connect_error', (error) => {
    console.error(`Connection error on ${eventName}:`, error);
  });

  socket[eventName].on('disconnect', () => {
    console.warn(`Disconnected from ${eventName}`);
  });
};

export const listenToEventStop = (eventName) => {
  if (socket[eventName]) {
    socket[eventName].disconnect();
    delete socket[eventName];
  }
};

export const GETData = (endpoint, params, singleResponse, actionType) => async (dispatch) => {
  try {
    const res = await axios.get(api + endpoint, { params });
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;
    dispatch({ type: actionType, payload });
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    throw err;
  }
};

export const POSTData = (endpoint, payload, actionType) => async (dispatch) => {
  if(!endpoint || !payload || !actionType) {
    throw new Error('Missing required parameters: endpoint, payload, or actionType');
  }

  try {
    const res = await axios.post(api + endpoint, payload);
    if (res.status !== 201) {
      throw new Error(`Error posting data to ${endpoint}: ${res.statusText || res.status}`);
    }
    dispatch({ type: actionType, payload: res.data });
    toast.success('Data added successfully');
  } catch (err) {
    console.error(`Error posting data to ${endpoint}:`, err);
    throw err;
  }
}

export const PATCHData = (endpoint, payload, actionType) => async (dispatch) => {
  if (!endpoint || !payload || !actionType) {
    throw new Error('Missing required parameters: endpoint, payload, or actionType');
  }

  try {
    const res = await axios.patch(`${api}${endpoint}`, payload);
    if (![200, 201].includes(res.status)) { //TODO: patch should be 200
      throw new Error(`Error patching data to ${endpoint}: ${res.statusText || res.status}`);
    }
    dispatch({ type: actionType, payload: res.data });
    toast.success('Data updated successfully');
  } catch (err) {
    console.error(`Error patching data to ${endpoint}:`, err);
    throw err;
  }
};

export const DELETEData = (endpoint, id, actionType) => async (dispatch) => {
  if (!endpoint || !id || !actionType) {
    throw new Error('Missing required parameters: endpoint, id, or actionType');
  }

  try {
    const res = await axios.delete(`${api}${endpoint}`, { data: { id } });
    if (res.status !== 201) { // should be 200
      throw new Error(`Error deleting data from ${endpoint}: ${res.statusText || res.status}`);
    }
    dispatch({ type: actionType, payload: res.data });
    toast.success('Data deleted successfully');
  } catch (err) {
    console.error(`Error deleting data from ${endpoint}:`, err);
    throw err;
  }
};