import { ACTION_TYPES } from "../actions/types";
import dayjs from "dayjs";

const initialState = {
  setupStatus: -1, // whether the app is setup or not. -1=not done yet, 0=failed, 1=success

  organizations: [],
  branches: [],

  // filter
  organizationId: "",
  branchId: "",

  // settings
  loading: false,
  users: [],
  moduleId: "",
  audiences: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_REPORTS_DATA:
      return {
        ...state,
        reportsData: action.payload,
      };
    case "GET_BRANCHES":
      return {
        ...state,
        branches: action.payload,
      };

    case "SET_DATE_RANGE":
      return {
        ...state,
        dateRange: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };
    case "UPDATE_REPORT_RANGE":
      return {
        ...state,
        reportRange: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_DURATION:
      return {
        ...state,
        reportDuration: action.payload,
      };

    case "GET_ORGANIZATIONS":
      return {
        ...state,
        organizations: action.payload,
      };

    case "UPDATE_ORGANIZATION_ID":
      return {
        ...state,
        organizationId: action.payload,
      };

    case "UPDATE_BRANCH_ID":
      return {
        ...state,
        branchId: action.payload,
      };

    case ACTION_TYPES.GET_REPORT_TYPES:
      return {
        ...state,
        reportTypes: action.payload,
      };

    case "GET_SERVICES":
      return {
        ...state,
        services: action.payload,
      };

    case "GET_SERVICES_SUBSCRIPTIONS":
      return {
        ...state,
        servicesSubscriptions: action.payload,
      };

    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
      };

    case ACTION_TYPES.UPDATE_MODULE_ID:
      return {
        ...state,
        moduleId: action.payload,
      };

    case ACTION_TYPES.GET_AUDIENCES:
      return {
        ...state,
        audiences: action.payload,
      };

    default:
      return state;
  }
}
