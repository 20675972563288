import { toast } from "react-toastify";
import io from "socket.io-client";
import { DELETEData, GETData, PATCHData, POSTData } from "./events";
import { ACTION_TYPES } from "./types";
import store from "../index";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      // dispatch only if the payload is for the active organization
      dispatch({
        type,
        payload,
      });
    });
  };

export const listenAuthorizations = () =>
  listenToEvent("apps_ss_authorization", "LISTEN_AUTHORIZATIONS", 1);

export const fetchAuthorizations = () => async (dispatch) => {
  try {
    console.log("payload", "payload");
    const endpoint = "/apps/ss/authorization";
    dispatch(GETData(endpoint, null, false, ACTION_TYPES.FETCH_AUTHORIZATIONS));
  } catch (err) {
    console.error("Error fetching authorizations:", err);
    throw err;
  }
};

export const createAuthorization =
  (data, onAuthorizationCreated) => async (dispatch) => {
    if (!data.name || !data.organizationId || !data.endpointId) {
      toast.error("Please fill out all required fields");
      return;
    }
    dispatch(creatingAuthorization(true));
    try {
      const endpoint = "/apps/ss/authorization";
      await dispatch(POSTData(endpoint, data, ACTION_TYPES.ADD_AUTHORIZATION));
    } catch (err) {
      console.error("Error adding authorization:", err);
      toast.error("Error adding authorization");
      throw err;
    }

    dispatch(creatingAuthorization(false));
    onAuthorizationCreated();
  };

export const updateAuthorization =
  (data, onAuthorizationCreated) => async (dispatch) => {
    if (!data.name || !data.organizationId || !data.endpointId) {
      toast.error("Please fill out all required fields");
      return;
    }
    dispatch(creatingAuthorization(true));
    try {
      const endpoint = "/apps/ss/authorization";
      await dispatch(PATCHData(endpoint, data, ACTION_TYPES.ADD_AUTHORIZATION));
    } catch (err) {
      console.error("Error updating authorization:", err);
      toast.error("Error updating authorization");
      throw err;
    }

    dispatch(creatingAuthorization(false));
    onAuthorizationCreated();
  };

export const deleteAuthorization =
  (id, onAuthorizationDeleted) => async (dispatch) => {
    try {
      const endpoint = "/apps/ss/authorization";
      await dispatch(DELETEData(endpoint, id, ACTION_TYPES.ADD_AUTHORIZATION));
    } catch (err) {
      console.error("Error deleting authorization:", err);
      toast.error("Error deleteting authorization");
      throw err;
    }

    onAuthorizationDeleted();
  };

export const setAuthorizationsLoading = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_AUTHORIZATIONS_LOADING,
    payload: payload,
  });
};

// UPDATE_LOCAL_AUTHORIZATION
export const updateLocalAuthorization = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_AUTHORIZATION,
    payload: payload,
  });
};

// UPDATE_CREATING_AUTHORIZATION
export const creatingAuthorization = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CREATING_AUTHORIZATION,
    payload: payload,
  });
};
