import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateBranchId } from "../../store/actions/defaultAction";
import { useEffect, useState } from "react";
import _ from "lodash";

import Select from "../averly/Select";

const BranchSelection = ({ selectedOrganizationId = null, value, onChange }) => {
  const dispatch = useDispatch();
  const { branches, organizationId, branchId } = useSelector(
    (store) => store.defaultReducer
  );
  const [branchesClone, setBranchesClones] = useState(
    _.cloneDeep(branches).filter((a) => a.organizationId === organizationId)
  );

  selectedOrganizationId = selectedOrganizationId === null ?  organizationId : selectedOrganizationId;
  value = value || branchId;

  useEffect(() => {
    const updatedBranches = _.cloneDeep(branches).filter(
      (a) => a.organizationId === selectedOrganizationId
    );
    setBranchesClones(updatedBranches);
  }, [branches, selectedOrganizationId]);

  const handleOnchange = (branchId) => {
    dispatch(updateBranchId({ branchId, organizationId }));
  };

  onChange = onChange || handleOnchange;

  return (
    <Select
      label="Branch"
      placeholder="All Branches"
      value={value}
      options={branchesClone}
      onChange={onChange}
    />
  );
};

export default BranchSelection;
