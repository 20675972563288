import { toast } from "react-toastify";
import io from "socket.io-client";
import { DELETEData, GETData, PATCHData, POSTData } from "./events";
import { ACTION_TYPES } from "./types";
import store from "../index";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      dispatch({
        type,
        payload,
      });
    });
  };

export const listenSubscriptions = () =>
  listenToEvent("apps_ss_modulesSubscriptions", "LISTEN_SUBSCRIPTIONS", 1);

export const fetchSubscriptions = () => async (dispatch) => {
  try {
    const endpoint = "/apps/ss/modulesSubscriptions";
    dispatch(GETData(endpoint, null, false, ACTION_TYPES.FETCH_SUBSCRIPTIONS));
  } catch (err) {
    console.error("Error fetching subscriptions:", err);
    throw err;
  }
};

export const createSubscription =
  (data, onSubscriptionCreated) => async (dispatch) => {
    if (
      !data.name ||
      !data.label ||
      !data.moduleId ||
      !data.organizationId ||
      !data.iconName ||
      !data.iconSet
    ) {
      toast.error("Please fill out all required fields");
      return;
    }
    dispatch(updateCreatingSubscription(true));
    try {
      const endpoint = "/apps/ss/modulesSubscriptions";
      await dispatch(POSTData(endpoint, data, ACTION_TYPES.ADD_SUBSCRIPTION));
    } catch (err) {
      console.error("Error adding subscription:", err);
      toast.error("Error adding subscription");
      throw err;
    }

    dispatch(updateCreatingSubscription(false));
    onSubscriptionCreated();
  };

export const updateSubscription =
  (data, onSubscriptionCreated) => async (dispatch) => {
    if (
      !data.name ||
      !data.label ||
      !data.moduleId ||
      !data.organizationId ||
      !data.iconName ||
      !data.iconSet
    ) {
      toast.error("Please fill out all required fields");
      return;
    }
    dispatch(updateCreatingSubscription(true));
    try {
      const endpoint = "/apps/ss/modulesSubscriptions";
      await dispatch(PATCHData(endpoint, data, ACTION_TYPES.ADD_SUBSCRIPTION));
    } catch (err) {
      console.error("Error updating subscription:", err);
      toast.error("Error updating subscription");
      throw err;
    }

    dispatch(updateCreatingSubscription(false));
    onSubscriptionCreated();
  };

export const deleteSubscription =
  (id, onSubscriptionDeleted) => async (dispatch) => {
    try {
      const endpoint = "/apps/ss/modulesSubscriptions";
      await dispatch(DELETEData(endpoint, id, ACTION_TYPES.ADD_SUBSCRIPTION));
    } catch (err) {
      console.error("Error deleting subscription:", err);
      toast.error("Error deleteting subscription");
      throw err;
    }

    onSubscriptionDeleted();
  };

export const setSubscriptionsLoading = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SUBSCRIPTIONS_LOADING,
    payload: payload,
  });
};

// UPDATE_LOCAL_SUBSCRIPTION
export const updateLocalSubscription = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_SUBSCRIPTION,
    payload: payload,
  });
};

// UPDATE_CREATING_SUBSCRIPTION
export const updateCreatingSubscription = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CREATING_SUBSCRIPTION,
    payload: payload,
  });
};
