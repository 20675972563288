import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Center,
  chakra,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Actions from "./Actions";

const ModuleSectionsTable = ({ data }) => {
  const [maxWidth, setMaxWidth] = useState(0);
  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  const { orgSubscriptions: subscriptions, name } = data;
  return (
    <TableContainer>
      <Center m={1}>
        <chakra.h1
          textAlign={"center"}
          fontSize={"4xl"}
          py={5}
          fontWeight={"bold"}
        >
          {name}
        </chakra.h1>
      </Center>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Label</Th>
            <Th>Module Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {subscriptions.map((a) => {
            return (
              <Tr key={a.id}>
                <Td>{a.name}</Td>
                <Td>{a.label}</Td>
                <Td>{a.moduleName}</Td>
                <Td>
                  <Actions subscription={a} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Name</Th>
            <Th>Label</Th>
            <Th>Module Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default ModuleSectionsTable;
