import { ACTION_TYPES } from "../actions/types";

const initialState = {
  subscriptions: [],
  subscriptionsLoading: false,
  creatingSubscription: false,
  listenSubscriptionsStart: 0,
  subscription: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case ACTION_TYPES.LISTEN_SUBSCRIPTIONS_START:
      return {
        ...state,
        listenSubscriptionsStart: action.payload,
      };

    case ACTION_TYPES.LISTEN_SUBSCRIPTIONS_CREATE:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_SUBSCRIPTIONS_UPDATE:
      return {
        ...state,
        subscriptions: state.subscriptions.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_SUBSCRIPTIONS_DELETE:
      return {
        ...state,
        subscriptions: state.subscriptions.filter((data) => data.id !== action.payload.id),
      };

    case ACTION_TYPES.SET_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        subscriptionsLoading: action.payload,
      };

    case ACTION_TYPES.UPDATE_LOCAL_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };

    case ACTION_TYPES.UPDATE_CREATING_SUBSCRIPTION:
      return {
        ...state,
        creatingSubscription: action.payload,
      };
    default:
      return state;
  }
}
