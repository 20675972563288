import { Box } from "@chakra-ui/react";
import FilterForm from "../components/FilterForm";
import { ACTION_BUTTONS } from "../store/constants";
import StylingComponent from "../components/styling/StylingComponent";

const StylingAndContent = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? (
        <FilterForm actionButton={ACTION_BUTTONS.CREATE_STYLING} />
      ) : null}
      <StylingComponent />
    </Box>
  );
};

export default StylingAndContent;