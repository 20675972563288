import { toast } from "react-toastify";
import io from "socket.io-client";
import { DELETEData, GETData, PATCHData, POSTData } from "./events";
import { ACTION_TYPES } from "./types";
import store from "../index";
import { PRODUCT_ID } from "../constants";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      console.log(startType, "event", res);
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      dispatch({
        type,
        payload,
      });
    });
  };

export const listenAdminEndpoints = () =>
  listenToEvent("admin_endpoints", "LISTEN_ADMIN_ENDPOINTS", 0);

export const fetchAdminEndpoints = () => async (dispatch) => {
  try {
    const endpoint = "/admin/endpoints";
    dispatch(
      GETData(
        endpoint,
        { productId: PRODUCT_ID },
        false,
        ACTION_TYPES.FETCH_ADMIN_ENDPOINTS
      )
    );
  } catch (err) {
    console.error("Error fetching admin endpoints:", err);
    throw err;
  }
};

export const createAdminEndpoint =
  (data, onEndpointCreated) => async (dispatch) => {
    if (
      !data.name ||
      !data.organizationId ||
      !data.branchId ||
      !data.audienceId
    ) {
      toast.error("Please fill out all required fields");
      return;
    }
    dispatch(updateCreatingAdminEndpoint(true));
    try {
      const endpoint = "/admin/endpoints";
      await dispatch(
        POSTData(
          endpoint,
          { ...data, productId: PRODUCT_ID, layoutId: "" },
          ACTION_TYPES.ADD_ENDPOINT
        )
      );
    } catch (err) {
      console.error("Error adding endpoint:", err);
      toast.error("Error adding endpoint");
      throw err;
    }

    dispatch(updateCreatingAdminEndpoint(false));
    onEndpointCreated();
  };

export const updateAdminEndpoint =
  (data, onEndpointCreated) => async (dispatch) => {
    if (
      !data.name ||
      !data.organizationId ||
      !data.branchId ||
      !data.audienceId
    ) {
      toast.error("Please fill out all required fields");
      return;
    }
    dispatch(updateCreatingAdminEndpoint(true));
    try {
      const endpoint = "/admin/endpoints";
      await dispatch(PATCHData(endpoint, data, ACTION_TYPES.ADD_ENDPOINT));
    } catch (err) {
      console.error("Error updating endpoint:", err);
      toast.error("Error updating endpoint");
      throw err;
    }

    dispatch(updateCreatingAdminEndpoint(false));
    onEndpointCreated();
  };

export const deleteAdminEndpoint =
  (id, onEndpointDeleted) => async (dispatch) => {
    try {
      const endpoint = "/admin/endpoints";
      await dispatch(DELETEData(endpoint, id, ACTION_TYPES.ADD_ENDPOINT));
    } catch (err) {
      console.error("Error deleting endpoint:", err);
      toast.error("Error deleteting endpoint");
      throw err;
    }

    onEndpointDeleted();
  };

export const setAdminEndpointsLoading = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADMIN_ENDPOINTS_LOADING,
    payload: payload,
  });
};

// UPDATE_LOCAL_ENDPOINT
export const updateLocalAdminEndpoint = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_ADMIN_ENDPOINT,
    payload: payload,
  });
};

// UPDATE_CREATING_ENDPOINT
export const updateCreatingAdminEndpoint = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CREATING_ADMIN_ENDPOINT,
    payload: payload,
  });
};
