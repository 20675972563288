import { ACTION_TYPES } from "../actions/types";

const initialState = {
  sections: [],
  sectionsLoading: false,
  creatingSection: false,
  listenSectionsStart: 0,
  section: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };

    case ACTION_TYPES.LISTEN_SECTIONS_START:
      return {
        ...state,
        listenSectionsStart: action.payload,
      };

    case ACTION_TYPES.LISTEN_SECTIONS_CREATE:
      return {
        ...state,
        sections: [...state.sections, action.payload].filter(
          (data, index, array) =>
            array.findIndex((data2) => data2.id === data.id) === index
        ),
      };
    case ACTION_TYPES.LISTEN_SECTIONS_UPDATE:
      return {
        ...state,
        sections: state.sections.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
      };
    case ACTION_TYPES.LISTEN_SECTIONS_DELETE:
      return {
        ...state,
        sections: state.sections.filter((data) => data.id !== action.payload.id),
      };

    case ACTION_TYPES.SET_SECTIONS_LOADING:
      return {
        ...state,
        sectionsLoading: action.payload,
      };

    case ACTION_TYPES.UPDATE_LOCAL_SECTION:
      return {
        ...state,
        section: action.payload,
      };

    case ACTION_TYPES.UPDATE_CREATING_SECTION:
      return {
        ...state,
        creatingSection: action.payload,
      };
    default:
      return state;
  }
}
