import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

import { resetDrawer } from "../store/actions/miscAction";
// components
import ModuleForm from "./modules/forms/ModuleForm";
import DeleteModuleForm from "./modules/forms/DeleteModuleForm";
import EndpointForm from "./endpoints/forms/EndpointForm";
import DeleteEndpointForm from "./endpoints/forms/DeleteEndpointForm";
import SubscriptionForm from "./subscriptions/forms/SubscriptionForm";
import ModuleEndpointsForm from "./subscriptions/forms/ModuleEndpointsForm";
import ModuleSectionForm from "./moduleSections/forms/ModuleSectionForm";
import DeleteSectionForm from "./moduleSections/forms/DeleteSectionForm";
import DeleteSubscriptionForm from "./subscriptions/forms/DeleteSubscriptionForm";
import AuthorizationForm from "./authorization/forms/AuthorizationForm";
import DeleteAuthorizationForm from "./authorization/forms/DeleteAuthorizationForm";
import AdminEndpointForm from "./adminEndpoints/forms/AdminEndpointForm";
import DeleteAdminEndpointForm from "./adminEndpoints/forms/DeleteAdminEndpointForm";
import StylingForm from "./styling/forms/StylingForm";
import DeleteStylingForm from "./styling/forms/DeleteStylingForm";

function DrawerComponent(props) {
  const dispatch = useDispatch();

  const { drawer } = useSelector((state) => state.miscReducer);
  const { title, content, isOpen } = drawer;

  const onClose = () => {
    dispatch(resetDrawer());
  };

  const Components = {
    ModuleForm: <ModuleForm />,
    DeleteModuleForm: <DeleteModuleForm />,
    EndpointForm: <EndpointForm />,
    DeleteEndpointForm: <DeleteEndpointForm />,
    SubscriptionForm: <SubscriptionForm />,
    ModuleEndpointsForm: <ModuleEndpointsForm />,
    ModuleSectionForm: <ModuleSectionForm />,
    DeleteSectionForm: <DeleteSectionForm />,
    DeleteSubscriptionForm: <DeleteSubscriptionForm />,
    AuthorizationForm: <AuthorizationForm />,
    DeleteAuthorizationForm: <DeleteAuthorizationForm />,
    AdminEndpointForm: <AdminEndpointForm />,
    DeleteAdminEndpointForm: <DeleteAdminEndpointForm />,
    StylingForm: <StylingForm />,
    DeleteStylingForm: <DeleteStylingForm />
  };

  return (
    <Drawer onClose={onClose} isOpen={Boolean(isOpen)} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader mt="50px">
          <Text fontSize="24px" fontWeight={400} color="var(--black)">
            {title}
          </Text>
        </DrawerHeader>
        <DrawerBody>{Components[content.component]}</DrawerBody>

        <DrawerFooter>
          <HStack justifyContent="center" width="full">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerComponent;
