import { useState, useEffect } from "react";
import MultiSelect from "react-select";
import { Box, Text } from "@chakra-ui/react";
import * as io5Icons from "react-icons/io5";
import * as mdIcons from "react-icons/md";
import * as faIcons from "react-icons/fa";

const IconNameSelection = ({ iconSet, onChange, value }) => {
  const [iconOptions, setIconOptions] = useState([]);

  useEffect(() => {
    let icons = [];
    switch (iconSet) {
      case "io5":
        icons = Object.keys(io5Icons).map((name) => ({ name, id: name }));
        break;
      case "md":
        icons = Object.keys(mdIcons).map((name) => ({ name, id: name }));
        break;
      case "fa":
        icons = Object.keys(faIcons).map((name) => ({ name, id: name }));
        break;
      default:
        icons = [];
    }
    setIconOptions(icons);
  }, [iconSet]);

return (
    <Box
      mt="10px"
      w="100%"
      h="auto"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 5px 9px 12px"
    >
      <Text h="15px" lineHeight="15px" fontSize="12px" color="#6a6a6a">
        {'Select Icon Name'}
      </Text>

      <MultiSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            outline: "none",
            boxShadow: "none",
            paddingLeft: 0,
            marginLeft: 0,
          }),
        }}
        options={iconOptions.map((a) => ({
          value: a.id,
          label: a.name,
        }))}
        value={{value, label: value}}
        onChange={(e) => {
          onChange(e?.value || "");
        }}
        isMulti={false}
        isClearable={true}
        isSearchable={true}
        placeholder="Select Icon"
      />
    </Box>
  );
};

export default IconNameSelection;
