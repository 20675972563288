import { Box } from "@chakra-ui/react";
import Select from "../../averly/Select";

const ModuleTypeSelection = ({ onChange, value }) => {
  const options = [
    { name: "Sign In", id: 1 },
    { name: "Other", id: 0 },
  ];
  return (
    <Box>
      <Select
        label="Module Type"
        placeholder="Select a Module Type"
        value={value}
        options={options}
        onChange={onChange}
      />
    </Box>
  );
};

export default ModuleTypeSelection;